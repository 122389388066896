import _ from 'lodash'
import React, { useState } from 'react'
import { Button, Space, Input, Popconfirm } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'

import FilterDropdown from '../components/FilterDropdown'

const useTableActions = () => {
  const [deletePopconfirmKey, setDeletePopconfirmKey] = useState(null)
  const [searchData, setSearchData] = useState({
    searchText: '',
    searchColumn: '',
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchData({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchData((prevState) => ({
      ...prevState,
      searchText: '',
      searchedColumn: '',
    }))
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <FilterDropdown
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        onFilter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        onClearFilter={() => handleReset(clearFilters)}
      />
    ),
    // filtered: searchData.searchedColumn === dataIndex,
    filteredValue:
      searchData.searchedColumn === dataIndex ? [searchData.searchText] : [],
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     ? record[dataIndex]
    //         .toString()
    //         .toLowerCase()
    //         .includes(value.toLowerCase())
    //     : '',
    render: (text) =>
      searchData.searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchData.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const getEditDeleteActions = (editHandler, deleteHandler, deleteLoading) => ({
    render: (text, record) => (
      <Space size={'small'}>
        <a onClick={() => editHandler(record._id)}>Edit</a>
        <Popconfirm
          visible={deletePopconfirmKey === record._id}
          title={'Are you sure?'}
          onConfirm={() => deleteHandler(record._id)}
          okButtonProps={{
            loading: deleteLoading,
            disabled: deleteLoading,
          }}
          onCancel={() => setDeletePopconfirmKey(null)}
        >
          <a onClick={() => setDeletePopconfirmKey(record._id)}>Delete</a>
        </Popconfirm>
      </Space>
    ),
  })

  const getAlphabeticalSorterProps = (param = 'name') => ({
    sorter: (a, b) => {
      if (_.get(a, param) === _.get(b, param)) return 0

      const unsortedArray = [_.get(a, param), _.get(b, param)]

      unsortedArray.sort()

      const [sortedOne] = unsortedArray

      if (sortedOne === _.get(a, param)) return -1
      if (sortedOne === _.get(b, param)) return 1

      return 0
    },
    sortDirections: ['ascend', 'descend'],
  })

  return {
    searchData,
    getColumnSearchProps,
    getEditDeleteActions,
    getAlphabeticalSorterProps,
  }
}

export default useTableActions

// <div style={{ padding: 8 }}>
// <Input
// ref={searchInputRef}
// placeholder={`Search ${dataIndex}`}
// value={selectedKeys[0]}
// onChange={(e) =>
// setSelectedKeys(e.target.value ? [e.target.value] : [])
// }
// onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
// style={{ width: 188, marginBottom: 8, display: 'block' }}
// />
// <Space>
//   <Button
//     type="primary"
//     onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//     icon={<SearchOutlined />}
//     size="small"
//     style={{ width: 90 }}
//   >
//     Search
//   </Button>
//   <Button
//     onClick={() => handleReset(clearFilters)}
//     size="small"
//     style={{ width: 90 }}
//   >
//     Reset
//   </Button>
// </Space>
// </div>
