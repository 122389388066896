import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Space, Button, Spin } from 'antd'

import {
  fetchProjectsDetails,
  updateProject,
} from '../../../store/actions/projects.actions'
import { fetchAssetTypesList } from '../../../store/actions/assetTypes.actions'
import ModifyDrawer from '../../ModifyDrawer'
import ProjectForm from '../ProjectForm'

const EditProject = ({ visible, onDrawerClose }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [
    projectsDetails,
    updatingProject,
    assetTypesList,
  ] = useSelector((state) => [
    state.projects.details,
    state.projects.updatingProject,
    state.assetTypes.list,
  ])

  useEffect(() => {
    if (visible) {
      dispatch(fetchProjectsDetails(visible))
    }
  }, [dispatch, visible])

  const projectCustomerId = projectsDetails.data?.customer?._id
  useEffect(() => {
    if (visible && projectCustomerId) {
      dispatch(fetchAssetTypesList({ customer: projectCustomerId }))
    }
  }, [dispatch, visible, projectCustomerId])

  const projectDetailsData = projectsDetails.data
  useEffect(() => {
    if (projectDetailsData) {
      form.setFieldsValue({
        name: projectDetailsData.name,
        assetConfigurations: projectDetailsData.assetConfigurations,
      })
    }
  }, [form, projectDetailsData])

  const updateProjectHandler = (values) => {
    dispatch(
      updateProject({
        id: visible,
        data: {
          ...projectsDetails.data,
          ...values,
        },
      })
    ).then(() => {
      form.resetFields()
      onDrawerClose()
    })
  }

  return (
    <ModifyDrawer
      title={'Update project'}
      visible={visible}
      onDrawerClose={onDrawerClose}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}>Cancel</Button>
          <Button
            type={'primary'}
            onClick={() => form.submit()}
            loading={updatingProject}
            disabled={updatingProject}
          >
            Save
          </Button>
        </Space>
      }
    >
      {projectsDetails.loading ? (
        <Spin />
      ) : (
        <ProjectForm
          form={form}
          onFinish={updateProjectHandler}
          assetTypesList={assetTypesList}
          showSuperUserFields={false}
        />
      )}
    </ModifyDrawer>
  )
}

export default EditProject
