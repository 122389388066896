import React from 'react'
import { Col, Divider, Row, Typography, Card } from 'antd'

import classes from './WorkOrderInfoSection.less'
import AssignedToTags from '../AssignedToTags'
import AssetList from '../AssetList'

const WorkOrderInfoSection = ({ workOrdersDetails }) => {
  return (
    <Card>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>Assigned to</Typography.Title>
        </Col>
        <Col span={24}>
          <AssignedToTags
            users={workOrdersDetails.data?.assignedToUsers}
            groups={workOrdersDetails.data?.assignedToGroups}
          />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>Instructions</Typography.Title>
        </Col>
        <Col xs={24} md={12}>
          <span className={classes.ShowEnterAndTab}>
            {workOrdersDetails.data?.instructions
              ? workOrdersDetails.data?.instructions
              : 'No instructions provided.'}
          </span>
        </Col>
      </Row>
      <Divider />
      <Typography.Title level={3}>Assets Targeted</Typography.Title>
      <AssetList dataSource={workOrdersDetails.data?.assetsTargeted} />
    </Card>
  )
}

export default WorkOrderInfoSection
