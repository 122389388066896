import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'

import {
  fetchResourcesList,
  fetchResourcesDetails,
  createFile,
  createFolder,
  updateResource,
  deleteResource,
} from '../actions/resources.actions'

const initialState = {
  cursor: {
    currentPrefix: '/',
    parentFolders: [],
  },
  list: {
    loading: false,
    data: [],
  },
  details: {
    loading: false,
    data: null,
  },
  creatingFile: false,
  creatingFolder: false,
  updatingResource: false,
  deletingResource: false,
}

const resourcesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchResourcesList.pending, (state, action) => {
      state.list.data = []
      state.list.loading = true
    })
    .addCase(fetchResourcesList.fulfilled, (state, action) => {
      state.list.data = action.payload

      state.cursor.currentPrefix = action.meta.arg.prefix
      state.cursor.parentFolders = _.reduce(
        _.split(action.meta.arg.prefix, '/'),
        (result, value) => {
          if (value === '') return result

          result.push(`${_.tail(result)}/${value}`)

          return result
        },
        ['/']
      )
      // state.cursor.parentFolders = _.reduce(
      //   _.initial(_.tail(_.split(action.meta.arg.prefix, '/'))),
      //   (result, value) => {
      //     if (!_.isEmpty(result)) {
      //       result.push(`${_.nth(result, -1)}/${value}`)
      //     } else if (value !== '') {
      //       result.push(value)
      //     }
      //
      //     return result
      //   },
      //   []
      // )

      state.list.loading = false
    })
    .addCase(fetchResourcesDetails.pending, (state, action) => {
      state.details.data = null
      state.details.loading = true
    })
    .addCase(fetchResourcesDetails.fulfilled, (state, action) => {
      state.details.data = action.payload
      state.details.loading = false
    })
    .addCase(createFile.pending, (state, action) => {
      state.creatingFile = true
    })
    .addCase(createFile.fulfilled, (state, action) => {
      state.list.data.push(action.payload)

      state.creatingFile = false
    })
    .addCase(createFolder.pending, (state, action) => {
      state.creatingFolder = true
    })
    .addCase(createFolder.fulfilled, (state, action) => {
      state.list.data.push(action.payload)

      state.creatingFolder = false
    })
    .addCase(updateResource.pending, (state, action) => {
      state.updatingResource = true
    })
    .addCase(updateResource.fulfilled, (state, action) => {
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[updatedIndex] = action.payload
      // Update the selected item
      state.details.data = action.payload

      state.updatingResource = false
    })
    .addCase(deleteResource.pending, (state, action) => {
      state.deletingResource = true
    })
    .addCase(deleteResource.fulfilled, (state, action) => {
      const deletedIndex = _.findIndex(state.list.data, ['_id', action.payload])

      // Delete from the list
      state.list.data.splice(deletedIndex, 1)

      // Delete if selected
      if (state.details.data?._id === action.payload) {
        state.details.data = null
      }

      state.deletingResource = false
    })
})

export default resourcesReducer
