import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Space, Button, Spin } from 'antd'

import {
  fetchAssetTypesDetails,
  updateAssetType,
} from '../../../store/actions/assetTypes.actions'
import ModifyDrawer from '../../ModifyDrawer'
import AssetTypeForm from '../AssetTypeForm'

const EditAssetType = ({ visible, onDrawerClose }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [assetTypesDetails, updatingAssetType] = useSelector((state) => [
    state.assetTypes.details,
    state.assetTypes.updatingAssetType,
  ])

  useEffect(() => {
    if (visible) {
      dispatch(fetchAssetTypesDetails(visible))
    }
  }, [visible, dispatch])

  const assetTypesDetailsData = assetTypesDetails.data
  useEffect(() => {
    if (assetTypesDetailsData) {
      form.setFieldsValue({
        ...assetTypesDetailsData,
      })
    }
  }, [form, assetTypesDetailsData])

  const updateAssetTypeHandler = (values) => {
    dispatch(
      updateAssetType({
        id: visible,
        data: {
          ...assetTypesDetails.data,
          ...values,
        },
      })
    ).then(() => {
      form.resetFields()
      onDrawerClose()
    })
  }

  return (
    <ModifyDrawer
      title={'Update Asset Type'}
      visible={visible}
      onDrawerClose={onDrawerClose}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}>Cancel</Button>
          <Button
            type={'primary'}
            onClick={() => form.submit()}
            loading={updatingAssetType}
            disabled={updatingAssetType}
          >
            Save
          </Button>
        </Space>
      }
    >
      {assetTypesDetails.loading ? (
        <Spin />
      ) : (
        <AssetTypeForm form={form} onFinish={updateAssetTypeHandler} />
      )}
    </ModifyDrawer>
  )
}

export default EditAssetType
