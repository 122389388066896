import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { message } from 'antd'

import {
  fetchProjectsList,
  fetchProjectsDetails,
  createProject,
  updateProject,
  deleteProject,
} from '../actions/projects.actions'

const initialState = {
  list: {
    loading: false,
    data: [],
  },
  details: {
    loading: false,
    data: null,
  },
  creatingProject: false,
  updatingProject: false,
  deletingProject: false,
}

const projectsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchProjectsList.pending, (state, action) => {
      state.list.data = []
      state.list.loading = true
    })
    .addCase(fetchProjectsList.fulfilled, (state, action) => {
      state.list.data = action.payload
      state.list.loading = false
    })
    .addCase(fetchProjectsDetails.pending, (state, action) => {
      state.details.data = null
      state.details.loading = true
    })
    .addCase(fetchProjectsDetails.fulfilled, (state, action) => {
      state.details.data = action.payload
      state.details.loading = false
    })
    .addCase(createProject.pending, (state, action) => {
      state.creatingProject = true
    })
    .addCase(createProject.fulfilled, (state, action) => {
      state.list.data.push(action.payload)

      state.creatingProject = false
    })
    .addCase(updateProject.pending, (state, action) => {
      state.updatingProject = true
    })
    .addCase(updateProject.fulfilled, (state, action) => {
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[updatedIndex] = action.payload
      // Update the selected item
      state.details.data = action.payload

      state.updatingProject = false
    })
    .addCase(deleteProject.pending, (state, action) => {
      state.deletingProject = true
    })
    .addCase(deleteProject.fulfilled, (state, action) => {
      const deletedIndex = _.findIndex(state.list.data, ['_id', action.payload])

      // Delete from the list
      state.list.data.splice(deletedIndex, 1)

      // Delete if selected
      if (state.details.data?._id === action.payload) {
        state.details.data = null
      }

      state.deletingProject = false
    })
})

export default projectsReducer
