import _ from 'lodash'
import { useSelector } from 'react-redux'

import { ACCESS_TYPES_OBJECT } from '../constants/fieldboxAccessTypes'

const useAuth = () => {
  const [isSuperUser, myActions] = useSelector((state) => [
    state.users.myCredentials.isSuperUser,
    state.users.myCredentials.myActions,
  ])

  const hasAnyAction = (actions = []) => {
    if (isSuperUser) return true

    return _.intersection(myActions, actions).length > 0
  }

  const hasAction = (action) => {
    return hasAnyAction([action])
  }

  return {
    isSuperUser,
    hasAction,
    hasAnyAction,
    allActions: ACCESS_TYPES_OBJECT,
  }
}

export default useAuth
