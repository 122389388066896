import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchUsersMe } from '../../store/actions/users.actions'
import { autoSignIn } from '../../store/actions/auth.actions'

import AuthorizedRoutes from '../AuthorizedRoutes'
import UnauthorizedRoutes from '../UnauthorizedRoutes'
import ApplicationLoading from '../../pages/ApplicationLoading'

export const App = () => {
  const dispatch = useDispatch()
  const [
    token,
    autoSignInLoading,
    userDataLoading,
    userDataLoaded,
  ] = useSelector((state) => [
    state.auth.token,
    state.auth.autoSignInLoading,
    state.users.me.loading,
    Boolean(state.users.me.data),
  ])

  useEffect(() => {
    dispatch(autoSignIn())
  }, [dispatch])

  useEffect(() => {
    if (token && !userDataLoaded && !userDataLoading) {
      dispatch(fetchUsersMe())
    }
  }, [token, dispatch, userDataLoaded, userDataLoading])

  let routes = <ApplicationLoading />
  if (!token && !userDataLoading && !autoSignInLoading)
    routes = <UnauthorizedRoutes />
  else if (token && userDataLoaded) routes = <AuthorizedRoutes />

  return <>{routes}</>
}

export default App
