import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Divider,
  DatePicker,
} from 'antd'

const CustomerForm = ({
  form,
  onFinish,
  onValuesChange = () => {},
  size = 'default',
  minLicenseNumber = 0,
  showResourceServerUrlDefault = false,
}) => {
  const [resourceServerSelected, setResourceServerSelected] = useState(false)

  useEffect(() => {
    // Change the default
    setResourceServerSelected(showResourceServerUrlDefault)
  }, [showResourceServerUrlDefault])

  const fieldsChangedHandler = (changedFields) => {
    const resourceServerFields = changedFields.filter(
      (changedField) =>
        changedField.name[0] === 'authProvider' &&
        changedField.name[1] === 'authType'
    )

    if (!_.isEmpty(resourceServerFields)) {
      setResourceServerSelected(
        resourceServerFields[0].value === 'resourceserver'
      )
    }
  }

  return (
    <Form
      size={size}
      layout={'vertical'}
      form={form}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      onFieldsChange={fieldsChangedHandler}
      initialValues={{
        maxLicenseNo: 10,
        authProvider: {
          authType: 'local',
        },
      }}
    >
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name={'name'}
            label={'Name'}
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Input placeholder={'Please provide the customer name'} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={'contactEmail'}
            label={'Contact email'}
            normalize={(value) => value.trim()}
            rules={[
              { required: true, message: 'This field is required!' },
              {
                type: 'email',
                message: 'Please provide a valid email address',
              },
            ]}
          >
            <Input placeholder={'Please provide a contact email'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name={'info'} label={'Info'}>
            <Input.TextArea
              placeholder={'Please provide some info about the customer'}
              autoSize={{ minRows: 3, maxRows: 5 }}
              maxLength={300}
              showCount
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation={'left'}>License Information</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={'maxLicenseNo'}
            label={'Max licenses'}
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={minLicenseNumber}
              precision={0}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'validUntil'} label={'Valid until'}>
            <DatePicker
              style={{ width: '100%' }}
              placeholder={'Indefinitely'}
              disabledDate={(currentDate) => {
                if (currentDate < new Date()) {
                  return true
                }

                return false
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation={'left'}>Authorization provider settings</Divider>
      <Row gutter={16}>
        <Col xs={24} md={resourceServerSelected ? 12 : 24}>
          <Form.Item
            name={['authProvider', 'authType']}
            label={'Authorization Source'}
          >
            <Select>
              <Select.Option value={'local'}>Local</Select.Option>
              <Select.Option value={'resourceserver'}>
                Resource Server
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {resourceServerSelected && (
          <Col xs={24} md={12}>
            <Form.Item
              name={['authProvider', 'param']}
              label={'Resource Server URL'}
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input placeholder={'https://user:pass@resserver.maptext.com'} />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Divider orientation={'left'}>Mobile ECW/JP2 license</Divider>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item
            name={['ecwLicense', 'companyName']}
            label={'Company name'}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={18}>
          <Form.Item name={['ecwLicense', 'key']} label={'Key'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default CustomerForm
