import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import qs from 'qs'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Row, Col, Spin, Card } from 'antd'

import { createCloudProvider } from '../../store/actions/cloudProviders.actions'
import CLOUD_PROVIDERS from '../../constants/cloudProviders'

const AuthorizeCloudProvider = () => {
  const dispatch = useDispatch()
  const [creatingCloudProvider] = useSelector((state) => [
    state.cloudProviders.creatingCloudProvider,
  ])
  const { search } = useLocation()
  const history = useHistory()
  const { cloudProvider } = useParams()

  const { code } = qs.parse(search, { ignoreQueryPrefix: true })
  const cloudProviderName = CLOUD_PROVIDERS[cloudProvider].name

  useEffect(() => {
    if (!code) history.replace('/settings/resource/me')
    else {
      dispatch(
        createCloudProvider({
          name: cloudProviderName,
          provider: cloudProvider,
          code,
          settings: {
            fullySetup: true,
          },
        })
      ).then(() => {
        // Go back to the settings

        history.replace('/settings/resource/me')
      })
    }
  }, [cloudProvider, cloudProviderName, dispatch, history, code])

  return (
    <Row justify={'center'}>
      <Col xs={24} md={16}>
        <Card title={`Authorize ${cloudProviderName}`}>
          <Row justify={'center'}>
            <Spin size={'large'} spinning={creatingCloudProvider} />
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

export default AuthorizeCloudProvider
