import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import Papa from 'papaparse'
import {
  Modal,
  Button,
  Upload,
  Row,
  Col,
  Typography,
  Form,
  Space,
  message,
  notification,
} from 'antd'
import { FileOutlined, CheckOutlined, DislikeOutlined } from '@ant-design/icons'

import { create } from '../../../axios/users'
import ImportUsersForm from './ImportUsersForm'
import { fetchRolesList } from '../../../store/actions/roles.actions'
import { fetchGroupsList } from '../../../store/actions/groups.actions'

const ImportUsers = ({
  visible,
  onModalClose,
  isSuperUser,
  customersList = [],
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [token, myUser, rolesList, groupsList] = useSelector((state) => [
    state.auth.token,
    state.users.me,
    state.roles.list,
    state.groups.list,
  ])
  const [usersToImport, setUsersToImport] = useState([])
  const [importingUsers, setImportingUsers] = useState(false)
  const [currentCustomer, setCurrentCustomer] = useState(
    myUser.data.customer._id
  )

  useEffect(() => {
    // Get roles and groups!
    if (visible) {
      dispatch(fetchRolesList({ customer: currentCustomer }))
      dispatch(fetchGroupsList({ customer: currentCustomer }))
    }
  }, [dispatch, visible, currentCustomer])

  // Auto select the ALL_USERS group for the new user!
  const groupsListData = groupsList.data
  useEffect(() => {
    if (groupsListData) {
      const allUsersGroup = _.find(groupsListData, ['name', 'ALL_USERS'])

      if (allUsersGroup) {
        form.setFieldsValue({
          groups: [allUsersGroup._id],
        })
      }
    }
  }, [form, groupsListData])

  // Auto select the FieldWorker role for the new user!
  const rolesListData = rolesList.data
  useEffect(() => {
    if (rolesListData) {
      const fieldWorkerRole = _.find(rolesListData, ['name', 'FieldWorker'])

      if (fieldWorkerRole) {
        form.setFieldsValue({
          roles: [fieldWorkerRole._id],
        })
      }
    }
  }, [form, rolesListData])

  const hideTheModalHandler = () => {
    setUsersToImport([])

    onModalClose()
  }

  const createUsersHandler = async (values) => {
    // Go through all the users and create them one by one
    setImportingUsers(true)
    try {
      const results = await Promise.allSettled(
        _.map(usersToImport, async (user) => {
          await create(
            {
              name: user.name,
              email: user.email,
              customer: currentCustomer,
              roles: values.roles,
              groups: values.groups,
            },
            {
              token,
            }
          )
        })
      )

      setUsersToImport([])
      onModalClose()

      // Count fails and successes
      const successesAndFails = _.countBy(results, 'status')

      notification.success({
        message: 'Import Finished!',
        description: (
          <Space direction={'vertical'}>
            <span>
              <CheckOutlined /> {successesAndFails.fulfilled} users imported
            </span>
            <span>
              <DislikeOutlined /> {successesAndFails.rejected} users failed to
              import
            </span>
          </Space>
        ),
      })

      message.success('Users imported successfully!')
    } catch (e) {
      message.error(`Failed to create one of the users!`)
    } finally {
      setImportingUsers(false)
    }
  }

  const uploadCSVFileHandler = (file) => {
    Papa.parse(file, {
      complete: (results) => {
        const filteredUsers = _.reduce(
          results.data,
          (result, user) => {
            const [name, email] = user

            if (name && email) {
              result.push({
                name: name.trim(),
                email: email.trim().toLowerCase(),
                status: 'idle', // Status of creating
              })
            }

            return result
          },
          []
        )

        setUsersToImport(filteredUsers)
      },
      error: () => {
        message.error('Failed to import from the file, check if the ')
      },
    })
  }

  const formFieldsChangeHandler = (changedFields) => {
    const customerFields = changedFields.filter(
      (changedField) => changedField.name[0] === 'customer'
    )

    if (customerFields.length > 0) {
      setCurrentCustomer(customerFields[0].value)
      form.resetFields(['roles', 'groups'])
    }
  }

  return (
    <Modal
      title={'Import users from a CSV file'}
      visible={visible}
      footer={[
        <Button
          type={'primary'}
          shape={'round'}
          disabled={_.isEmpty(usersToImport) || importingUsers}
          loading={importingUsers}
          onClick={() => form.submit()}
        >
          Import
        </Button>,
        <Button onClick={hideTheModalHandler} shape={'round'}>Cancel</Button>,
      ]}
      onCancel={hideTheModalHandler}
    >
      <ImportUsersForm
        form={form}
        onFinish={createUsersHandler}
        onFieldsChange={formFieldsChangeHandler}
        rolesList={rolesList}
        groupsList={groupsList}
        defaultCustomer={currentCustomer}
        customersList={customersList}
        usersToImport={usersToImport}
        isSuperUser={isSuperUser}
      />
      {_.isEmpty(usersToImport) && (
        <>
          <Row justify={'center'} gutter={[0, 35]}>
            <Col span={24}>
              <Typography.Text>
                Please select a file containing the users list using the
                following format:{' '}
              </Typography.Text>
              <Typography.Text code>name, email</Typography.Text>
            </Col>
          </Row>
          <Row justify={'center'}>
            <Col>
              <Upload
                name={'importFromCSV'}
                accept={'text/csv'}
                showUploadList={false}
                multiple={false}
                beforeUpload={uploadCSVFileHandler}
              >
                <Button icon={<FileOutlined />} shape={'round'}>Import from CSV</Button>
              </Upload>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  )
}

export default ImportUsers
