const userPasswordRules = [
  { required: true, message: 'This field is required!' },
  { min: 8, message: 'The password has to have at least 8 characters' },
  {
    pattern: /\d/,
    message: 'The password has to have at least one digit',
  },
  {
    pattern: /\W/,
    message: 'The password has to have at least one symbol',
  },
  {
    pattern: /[A-Z]/,
    message: 'The password has to have at least one capital letter',
  },
  {
    pattern: /[a-z]/,
    message: 'The password has to have at least one lower case letter',
  },
]

export default userPasswordRules
