import React from 'react'
import { Card, Image } from 'antd'
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons'

import classes from './Folder.less'
import folderImage from '../../../assets/images/folder24.png'

const Folder = ({ name, changePrefix, onDelete }) => {
  return (
    <Card
      actions={[
        <SettingOutlined key={'setting'} />,
        <DeleteOutlined
          key={'delete'}
          className={classes.DeleteIcon}
          onClick={onDelete}
        />,
      ]}
      hoverable
    >
      <Card.Meta
        onClick={changePrefix}
        avatar={<Image src={folderImage} preview={false} width={24} />}
        title={name}
      />
    </Card>
  )
}

export default Folder
