import React from 'react'
import moment from 'moment'
import { Col, Form, Input, Row, Select, DatePicker } from 'antd'

import classes from './DetailsForm.less'
import PriorityIcon from '../PriorityIcon'

const DetailsForm = ({
  form,
  onFinish,
  onFieldsChange,
  customersList,
  projectsList,
  defaultCustomer,
  showSuperUserFields = false,
  forceIntoProject = null,
}) => {
  return (
    <Form
      layout={'vertical'}
      form={form}
      preserve={true}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      initialValues={{
        customer: forceIntoProject
          ? forceIntoProject.customer._id
          : defaultCustomer,
        project: forceIntoProject ? forceIntoProject._id : undefined,
        type: 'unplanned',
        priority: 1,
        startAt: moment(),
        dueAt: moment(),
      }}
    >
      <Row gutter={16}>
        {showSuperUserFields && (
          <Col span={12}>
            <Form.Item
              name={'customer'}
              label={'Customer'}
              tooltip={'Super User setting'}
            >
              <Select
                loading={customersList.loading}
                disabled={Boolean(forceIntoProject) || customersList.loading}
                options={customersList.data.map((customer) => ({
                  label: customer.name,
                  value: customer._id,
                }))}
              />
            </Form.Item>
          </Col>
        )}
        <Col span={showSuperUserFields ? 12 : 24}>
          <Form.Item
            name={'project'}
            label={'Project'}
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Select
              loading={projectsList.loading}
              disabled={Boolean(forceIntoProject) || projectsList.loading}
              options={projectsList.data.map((project) => ({
                label: project.name,
                value: project._id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name={'name'}
            label={'Name'}
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name={'type'} label={'Type'}>
            <Select>
              <Select.Option value={'unplanned'}>Unplanned</Select.Option>
              <Select.Option value={'planned'}>Planned</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name={'priority'} label={'Priority'}>
            <Select>
              <Select.Option value={1}>
                <PriorityIcon priorityLevel={1} /> Lowest
              </Select.Option>
              <Select.Option value={2}>
                <PriorityIcon priorityLevel={2} /> Low
              </Select.Option>
              <Select.Option value={3}>
                <PriorityIcon priorityLevel={3} /> Medium
              </Select.Option>
              <Select.Option value={4}>
                <PriorityIcon priorityLevel={4} /> Medium-High
              </Select.Option>
              <Select.Option value={5}>
                <PriorityIcon priorityLevel={5} /> High
              </Select.Option>
              <Select.Option value={6}>
                <PriorityIcon priorityLevel={6} /> Top
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name={'startAt'}
            label={'Start at'}
            tooltip={
              'Specify since when do the assigned individuals have access to this work order'
            }
          >
            <DatePicker
              allowClear={false}
              className={classes.DatePicker}
              showTime
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={'dueAt'} label={'Due at'}>
            <DatePicker
              allowClear={false}
              className={classes.DatePicker}
              showTime
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name={'instructions'} label={'Instructions'}>
            <Input.TextArea
              maxLength={1000}
              autoSize={{ minRows: 3, maxRows: 6 }}
              showCount
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default DetailsForm
