import React from 'react'
import { Form, Row, Col, Button, Select, Upload, Divider, Image } from 'antd'
import { FileTextOutlined } from '@ant-design/icons'

import fileIcon from '../../../assets/images/file64.png'
import GroupTag from '../../Group/GroupTag'

const FileForm = ({
  form,
  onFinish,
  groupsList,
  fileList,
  addFileToUpload,
  removeFileFromUpload,
}) => {
  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name={'permissions'}
            label={'Groups with access to the files'}
          >
            <Select
              loading={groupsList.loading}
              disabled={groupsList.loading}
              options={groupsList.data.map((group) => ({
                label: group.name,
                value: group._id,
              }))}
              tagRender={({ label, value, closable, onClose }) => (
                <GroupTag name={label} closable={closable} onClose={onClose} />
              )}
              mode={'multiple'}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <Upload
            name={'files'}
            beforeUpload={(file) => {
              addFileToUpload(file)
              return false
            }}
            onRemove={(file) => removeFileFromUpload(file)}
            showUploadList={{ showRemoveIcon: true }}
            iconRender={() => (
              <Image src={fileIcon} preview={false} width={48} />
            )}
            fileList={fileList}
            listType={'picture'}
            maxCount={5}
            multiple
          >
            <Button icon={<FileTextOutlined />}>
              Select file(s) to upload
            </Button>
          </Upload>
        </Col>
      </Row>
    </Form>
  )
}

export default FileForm
