import axiosBackendInstance from './backendInstance'

export const list = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get('/fb/role', {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
    params: {
      customer: data.customer,
      name: data.name,
      limit: requestProps.limit,
      page: requestProps.page,
      orderField: requestProps.orderField,
      orderDirection: requestProps.orderDirection,
    },
  })

  return responseData
}

export const details = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    `/fb/role/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const create = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.post(
    '/fb/role',
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const update = async ({ data, id }, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.put(
    `/fb/role/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const deleteRole = async (id, requestProps) => {
  await axiosBackendInstance.delete(`/fb/role/${id}`, {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
  })

  return id
}

export const bulkDeleteRoles = async (ids, requestProps) => {
  await axiosBackendInstance.post('/fb/role/bulkDelete', ids, {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
  })

  return ids
}
