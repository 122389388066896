import React, { useState } from 'react'
import _ from 'lodash'
import {
  Button,
  Typography,
  Row,
  Col,
  Card,
  Space,
  Table,
  Popconfirm,
  Tooltip,
  Grid,
} from 'antd'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import classes from './TableContainer.less'

const TableContainer = ({
  title = '',
  tooltip = null,
  topBarActions = [],
  onBulkDelete = null,
  bulkDeleteLoading = false,
  tableProps = {},
  queryStringDefaults = {},
  children,
}) => {
  const screens = Grid.useBreakpoint()
  const [selectedRows, setSelectedRows] = useState([])
  const [confirmingBulkDelete, setConfirmingBulkDelete] = useState(false)

  const isXs = screens.xs && !screens.sm

  const bulkDeleteHandler = () => {
    onBulkDelete(selectedRows)
    setSelectedRows([])
    setConfirmingBulkDelete(false)
  }

  const bulkDeleteActions = (
    <Popconfirm
      title={`Are you sure to delete ${selectedRows.length} items?`}
      placement={'bottomRight'}
      visible={confirmingBulkDelete}
      onConfirm={bulkDeleteHandler}
      okButtonProps={{
        loading: bulkDeleteLoading,
        disabled: bulkDeleteLoading,
      }}
      cancelButtonProps={{
        disabled: bulkDeleteLoading,
      }}
      onCancel={() => setConfirmingBulkDelete(false)}
    >
      <Button
        icon={<DeleteOutlined />}
        onClick={() => setConfirmingBulkDelete(true)}
        danger
      >
        Delete selected
      </Button>
    </Popconfirm>
  )

  const defaultTable = (
    <Table
      rowKey={'_id'}
      rowSelection={
        onBulkDelete && {
          selectedRowKeys: selectedRows,
          onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys),
        }
      }
      scroll={{ x: 900 }}
      {...tableProps}
      pagination={{
        defaultPageSize: +queryStringDefaults.limit || 10,
        defaultCurrent: +queryStringDefaults.page || 1,
        ...tableProps.pagination,
      }}
    />
  )

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Card>
            <Row align={'middle'}>
              <Col flex={'auto'}>
                <Space size={'small'}>
                  <Typography.Title
                    level={isXs ? 5 : 1}
                    className={classes.Title}
                  >
                    {title}
                  </Typography.Title>
                  {tooltip && (
                    <Tooltip title={tooltip} placement={'bottomRight'}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  )}
                </Space>
              </Col>
              <Col flex={'none'}>
                <Space size={'small'}>
                  {_.isEmpty(selectedRows) ? topBarActions : bulkDeleteActions}
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Card bodyStyle={{ padding: '8px' }} className={classes.Card}>
            {/* Insert the table/list here */}
            {children ? children : defaultTable}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default TableContainer
