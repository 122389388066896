import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Row, Col, Card, Typography } from 'antd'
import { FolderAddOutlined, CloudUploadOutlined } from '@ant-design/icons'

import { fetchCloudProvidersMe } from '../../store/actions/cloudProviders.actions'
import { fetchCustomersMe } from '../../store/actions/customers.actions'
import {
  fetchResourcesList,
  deleteResource,
} from '../../store/actions/resources.actions'
import { fetchGroupsList } from '../../store/actions/groups.actions'

import TableContainer from '../../components/TableContainer'
import ResourcesNavBar from '../../components/Resource/ResourcesNavBar'
import ResourcesListView from '../../components/Resource/ResourcesListView'
import ResourcesGridView from '../../components/Resource/ResourcesGridView'
import CreateFile from '../../components/Resource/CreateFile'
import CreateFolder from '../../components/Resource/CreateFolder'

const Resources = () => {
  const dispatch = useDispatch()
  const [listView, setListView] = useState(false)
  const [createFolder, setCreateFolder] = useState(false)
  const [createFile, setCreateFile] = useState(false)
  const [
    meCustomer,
    meCloudProvider,
    resourcesList,
    groupsList,
    cursor,
  ] = useSelector((state) => [
    state.customers.me,
    state.cloudProviders.me,
    state.resources.list,
    state.groups.list,
    state.resources.cursor,
  ])

  useEffect(() => {
    dispatch(fetchCustomersMe())
    dispatch(fetchCloudProvidersMe())
  }, [dispatch])

  const meCustomerData = meCustomer.data
  const meCloudProviderData = meCloudProvider.data
  useEffect(() => {
    if (meCustomerData && meCloudProviderData) {
      dispatch(fetchGroupsList({ customer: meCustomer.data?._id }))
      dispatch(fetchResourcesList({ prefix: '/' }))
    }
  }, [meCustomer, meCloudProviderData, dispatch, meCustomerData])

  const changePrefixHandler = (newPrefix) => {
    dispatch(fetchResourcesList({ prefix: newPrefix }))
  }

  const deleteResourceHandler = (key, id) => {
    dispatch(
      deleteResource({
        key,
        id,
      })
    )
  }

  // Check if there is a Cloud Provider connected
  if (!meCloudProvider.loading && !meCloudProvider.data) {
    return (
      <Row justify={'center'}>
        <Col span={10}>
          <Card>
            <Row justify={'center'} gutter={[0, 10]}>
              <Col>
                <Typography.Title level={5}>
                  In order to view your resources, please authorize a cloud
                  provider
                </Typography.Title>
              </Col>
            </Row>
            <Row justify={'center'}>
              <Col>
                <Button type={'primary'}>
                  <Link to={'/settings/resource/me'}>Go to Settings</Link>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <CreateFile
        visible={createFile}
        onDrawerClose={() => setCreateFile(false)}
        groupsList={groupsList}
      />
      <CreateFolder
        visible={createFolder}
        onDrawerClose={() => setCreateFolder(false)}
        groupsList={groupsList}
        currentPrefix={cursor.currentPrefix}
      />
      <TableContainer
        title={`${meCloudProviderData?.name} - Resources`}
        topBarActions={[
          <Button
            key={'0'}
            icon={<CloudUploadOutlined />}
            type={'primary'}
            shape={'round'}
            size={'large'}
            onClick={() => setCreateFile(true)}
          >
            Upload File
          </Button>,
          <Button
            key={'1'}
            icon={<FolderAddOutlined />}
            type={'primary'}
            shape={'round'}
            size={'large'}
            onClick={() => setCreateFolder(true)}
          >
            Create Folder
          </Button>,
        ]}
      >
        <ResourcesNavBar
          listView={listView}
          toggleListView={() => setListView((prevView) => !prevView)}
          cursor={cursor}
          changePrefix={changePrefixHandler}
        />
        {listView ? (
          <ResourcesListView
            resources={resourcesList.data}
            groups={groupsList.data}
            loading={
              meCustomer.loading || resourcesList.loading || groupsList.loading
            }
            changePrefix={changePrefixHandler}
          />
        ) : (
          <ResourcesGridView
            resources={resourcesList.data}
            loading={meCustomer.loading || resourcesList.loading}
            changePrefix={changePrefixHandler}
            onDelete={deleteResourceHandler}
          />
        )}
      </TableContainer>
    </>
  )
}

export default Resources
