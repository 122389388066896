import { createAsyncThunk } from '@reduxjs/toolkit'

import * as licensesApi from '../../axios/licenses'
import { showMessage } from './messages.actions'

export const fetchLicensesList = createAsyncThunk(
  'licenses/list',
  async (
    { customer, email, status, limit, page, orderField, orderDirection } = {},
    thunkAPI
  ) => {
    const response = await licensesApi.list(
      { customer, email, status },
      {
        token: thunkAPI.getState().auth.token,
        limit,
        page,
        orderField,
        orderDirection,
      }
    )

    return response
  }
)

export const approveLicense = createAsyncThunk(
  'licenses/approve',
  async ({ id }, thunkAPI) => {
    const response = await licensesApi.approve(
      {
        id,
      },
      { token: thunkAPI.getState().auth.token }
    )

    thunkAPI.dispatch(
      showMessage({ type: 'success', config: { content: 'License approved!' } })
    )

    return response
  }
)

export const revokeLicense = createAsyncThunk(
  'licenses/revoke',
  async ({ id }, thunkAPI) => {
    const response = await licensesApi.revoke(
      {
        id,
      },
      { token: thunkAPI.getState().auth.token }
    )

    thunkAPI.dispatch(
      showMessage({ type: 'success', config: { content: 'License revoked!' } })
    )

    return response
  }
)
