import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { Card, Row, Col, Breadcrumb, Button, Tooltip } from 'antd'
import {
  HomeOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  SyncOutlined,
} from '@ant-design/icons'

import classes from './ResourcesNavBar.less'
import { syncWithCloudProvider } from '../../../store/actions/cloudProviders.actions'

const ResourcesNavBar = ({
  listView,
  toggleListView,
  cursor,
  changePrefix,
}) => {
  const dispatch = useDispatch()
  const [syncingWithCloudProvider] = useSelector((state) => [
    state.cloudProviders.syncingWithCloudProvider,
  ])

  const syncWithCloudProviderHandler = () => {
    dispatch(syncWithCloudProvider())
  }

  return (
    <Row style={{ marginBottom: '10px' }}>
      <Card className={classes.CardContainer}>
        <Row align={'middle'} wrap={false}>
          <Col flex={'auto'}>
            <Breadcrumb separator={'>'}>
              <Breadcrumb.Item
                href={'javascript:void(0)'}
                onClick={() => changePrefix('/')}
              >
                <HomeOutlined />
              </Breadcrumb.Item>
              {cursor.parentFolders.map((parentFolder) => (
                <Breadcrumb.Item
                  key={parentFolder}
                  href={'javascript:void(0)'}
                  onClick={() => changePrefix(parentFolder)}
                >
                  {_.nth(_.split(parentFolder, '/'), -1)}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </Col>
          <Col flex={'none'}>
            <Button
              shape={'circle'}
              type={'text'}
              onClick={syncWithCloudProviderHandler}
              disabled={syncingWithCloudProvider}
            >
              <Tooltip title={'Sync with Cloud Provider'}>
                <SyncOutlined spin={syncingWithCloudProvider} />
              </Tooltip>
            </Button>
            <Button shape={'circle'} type={'text'} onClick={toggleListView}>
              {listView ? (
                <Tooltip title={'Show as Grid'}>
                  <AppstoreOutlined />
                </Tooltip>
              ) : (
                <Tooltip title={'Show as List'}>
                  <UnorderedListOutlined />
                </Tooltip>
              )}
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  )
}

export default ResourcesNavBar
