import React from 'react'
import { Tag } from 'antd'
import { TeamOutlined } from '@ant-design/icons'

const GroupTag = ({ name, ...props }) => (
  <Tag color={'green'} icon={<TeamOutlined />} {...props}>
    {name}
  </Tag>
)

export default GroupTag
