import axiosBackendInstance from './backendInstance'

export const list = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get('/fb/license', {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
    params: {
      customer: data.customer,
      email: data.email,
      status: data.status,
      limit: requestProps.limit,
      page: requestProps.page,
      orderField: requestProps.orderField,
      orderDirection: requestProps.orderDirection,
    },
  })

  return responseData
}

export const approve = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.put(
    `/fb/license/approve/${data.id}`,
    {
      id: data.id,
    },
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const revoke = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.put(
    `/fb/license/revoke/${data.id}`,
    {
      id: data.id,
    },
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}
