export const ACCESS_TYPES = [
  {
    id: 'adminactions',
    name: 'Admin Actions',
    description: 'Manage Users, User Groups, User Roles, Licenses, Devices',
    system: 'fieldbox',
  },
  {
    id: 'viewhome',
    name: 'View Home',
    description: 'View Home Screen',
    system: 'fieldbox',
  },
  {
    id: 'managecustomersettings',
    name: 'Manage Customer Settings',
    description: 'Possibility to change settings of the current customer',
    system: 'fieldbox',
  },
  {
    id: 'manageassets',
    name: 'Manage Assets',
    description:
      'Manage folders, Data Upload, Delete, Rename, Download, Trigger sync base upload' +
      'to MongoDB, Manage Asset Groups assignment',
    system: 'fieldbox',
  },
  {
    id: 'manageallassets',
    name: 'Manage All Assets',
    description: 'Manage assets of all groups',
    system: 'fieldbox',
  },
  {
    id: 'viewassets',
    name: 'View Assets',
    description: 'Possibility to view a list of assets',
    system: 'fieldbox',
  },
  {
    id: 'receiveupdatenotification',
    name: 'Receive update notification',
    description:
      'User with this action assigned will be receiving notifications on updates',
    system: 'fieldbox',
  },
  {
    id: 'exportdatachanges',
    name: 'Export data changes',
    description: 'Export data changes (changes in the MongoDB)',
    system: 'fieldbox',
  },
  {
    id: 'fm_default',
    name: 'Default',
    description:
      'Login-based data and form download, view Map/PDF/Media, search, other standard functionality',
    system: 'fieldmap',
  },
  {
    id: 'fm_datacollection',
    name: 'Data Collection',
    description: 'Feature geometry creation or modification',
    system: 'fieldmap',
  },
  {
    id: 'fm_redline',
    name: 'Redline',
    description: 'Redline annotation creation or modification',
    system: 'fieldmap',
  },
  {
    id: 'fm_damageassessment',
    name: 'Damage Assessment',
    description: 'Damage Assessment annotation creation or modification',
    system: 'fieldmap',
  },
  {
    id: 'fm_pdfmarkup',
    name: 'PDF Markup',
    description: 'Capability of adding markups to PDF files',
    system: 'fieldmap',
  },
]

export const ACCESS_TYPES_OBJECT = {
  adminactions: 'adminactions',
  viewhome: 'viewhome',
  managecustomersettings: 'managecustomersettings',
  manageassets: 'manageassets',
  manageallassets: 'manageallassets',
  viewassets: 'viewassets',
  receiveupdatenotification: 'receiveupdatenotification',
  exportdatachanges: 'exportdatachanges',
  fm_default: 'fm_default',
  fm_datacollection: 'fm_datacollection',
  fm_redline: 'fm_redline',
  fm_damageassessment: 'fm_damageassessment',
  fm_pdfmarkup: 'fm_pdfmarkup',
}
