import React, { useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Card, Select, Space } from 'antd'

const ChartContainer = ({
  charts = [],
  defaultChartIndex = 0,
  monthSpanValues = [3, 6, 12],
  defaultMonthSpanValue = 6,
}) => {
  const [monthsSpan, setMonthsSpan] = useState(defaultMonthSpanValue)
  const [selectedChart, setSelectedChart] = useState(defaultChartIndex)

  let labels = []
  for (let i = 0; i < monthsSpan; i++) {
    labels.push(moment().subtract(i, 'month').format('MMMM'))
  }
  labels = _.reverse(labels)

  return (
    <Card
      title={
        <Select
          value={selectedChart}
          onSelect={(value) => setSelectedChart(value)}
          options={charts.map((chart, index) => ({
            label: chart.name,
            value: index,
          }))}
          bordered={false}
        />
      }
      extra={
        <Space>
          <Select
            value={monthsSpan}
            onChange={(value) => setMonthsSpan(value)}
            options={monthSpanValues.map((value) => ({
              label: `Last ${value} Months`,
              value,
            }))}
          />
        </Space>
      }
    >
      {charts[selectedChart].showChart(labels, monthsSpan)}
    </Card>
  )
}

export default ChartContainer
