import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import useAuth from '../../hooks/useAuth'
import { fetchProjectsList } from '../../store/actions/projects.actions'

import CreateProject from '../../components/Project/CreateProject'
import TableContainer from '../../components/TableContainer'
import useTableActions from '../../hooks/useTableActions'
import CustomerTag from '../../components/Customer/CustomerTag'

const Projects = () => {
  const dispatch = useDispatch()
  const { isSuperUser } = useAuth()
  const [createProject, setCreateProject] = useState(false)
  const { getColumnSearchProps, getAlphabeticalSorterProps } = useTableActions()
  const [projectsList] = useSelector((state) => [state.projects.list])

  useEffect(() => {
    dispatch(fetchProjectsList())
  }, [dispatch])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      ...getAlphabeticalSorterProps(),
    },
    {
      title: 'Customer',
      dataIndex: ['customer', 'name'],
      className: isSuperUser ? null : 'fb-column-hidden',
      render: (customer) => <CustomerTag name={customer} />,
      ...getAlphabeticalSorterProps(),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => (
        <Space size={'small'}>
          <a>
            <Link to={`/projects/details/${record._id}`}>Show</Link>
          </a>
        </Space>
      ),
    },
  ]

  return (
    <>
      <CreateProject
        visible={createProject}
        onDrawerClose={() => setCreateProject(false)}
      />
      <TableContainer
        title={'Projects'}
        topBarActions={[
          <Button
            key={'0'}
            icon={<PlusOutlined />}
            type={'primary'}
            shape={'round'}
            size={'large'}
            onClick={() => setCreateProject(true)}
          >
            Add Project
          </Button>,
        ]}
        tableProps={{
          columns,
          dataSource: projectsList.data,
          loading: projectsList.loading,
        }}
      />
    </>
  )
}

export default Projects
