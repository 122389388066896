import React from 'react'
import { Col, Form, Row, Select } from 'antd'

import UserTag from '../../User/UserTag'
import GroupTag from '../../Group/GroupTag'

const AssignedToForm = ({ form, onFinish, usersList, groupsList }) => {
  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name={'assignedToUsers'} label={'Assign to users'}>
            <Select
              loading={usersList.loading}
              disabled={usersList.loading}
              options={usersList.data.map((user) => ({
                label: user.name,
                value: user._id,
              }))}
              tagRender={({ label, value, closable, onClose }) => (
                <UserTag name={label} closable={closable} onClose={onClose} />
              )}
              mode={'multiple'}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name={'assignedToGroups'} label={'Assign to groups'}>
            <Select
              loading={groupsList.loading}
              disabled={groupsList.loading}
              options={groupsList.data.map((group) => ({
                label: group.name,
                value: group._id,
              }))}
              tagRender={({ label, value, closable, onClose }) => (
                <GroupTag name={label} closable={closable} onClose={onClose} />
              )}
              mode={'multiple'}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default AssignedToForm
