import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Row, Col, Popconfirm, Space, Divider } from 'antd'
import { PlusOutlined, UsergroupAddOutlined } from '@ant-design/icons'

import {
  deleteUser,
  fetchUsersList,
  bulkDeleteUsers,
  forgotPassword,
} from '../../store/actions/users.actions'
import { fetchRolesList } from '../../store/actions/roles.actions'
import { fetchCustomersList } from '../../store/actions/customers.actions'

import useAuth from '../../hooks/useAuth'
import useTable from '../../hooks/useTable'
import CustomerTag from '../../components/Customer/CustomerTag'
import SuperUserTag from '../../components/User/SuperUserTag'
import RoleTag from '../../components/Role/RoleTag'
import TableContainer from '../../components/TableContainer'
import CreateUser from '../../components/User/CreateUser'
import EditUser from '../../components/User/EditUser'
import ImportUsers from '../../components/User/ImportUsers'
import FilterDropdown from '../../components/FilterDropdown'
import ResourceServerUserTag from '../../components/User/ResourceServerUserTag'

const Users = () => {
  const dispatch = useDispatch()
  const { getQueryObject, queryStringDefaults } = useTable()
  const { isSuperUser } = useAuth()
  const [createUser, setCreateUser] = useState(false)
  const [editUser, setEditUser] = useState(null)
  const [deleteUserId, setDeleteUserId] = useState(null)
  const [resetPasswordEmail, setResetPasswordEmail] = useState(null)
  const [importUsers, setImportUsers] = useState(false)
  const [
    usersMe,
    usersList,
    customersList,
    rolesList,
    deletingUser,
    bulkDeletingUsers,
    forgotPasswordSending,
  ] = useSelector((state) => [
    state.users.me,
    state.users.list,
    state.customers.list,
    state.roles.list,
    state.users.deletingUser,
    state.users.bulkDeletingUsers,
    state.users.forgotPasswordSending,
  ])

  const resetPasswordHandler = (email) => {
    dispatch(forgotPassword({ email })).then(() => {
      setResetPasswordEmail(null)
    })
  }

  const deleteUserHandler = (id) => {
    dispatch(deleteUser(id))
  }

  const bulkDeleteUsersHandler = (ids) => {
    dispatch(bulkDeleteUsers(ids))
  }

  useEffect(() => {
    if (!importUsers && !createUser && !editUser) {
      dispatch(fetchUsersList(queryStringDefaults))
    }
  }, [dispatch, queryStringDefaults, importUsers, createUser, editUser])

  useEffect(() => {
    if (!createUser && !editUser && !importUsers) {
      dispatch(
        fetchRolesList({
          orderField: 'name',
          orderDirection: 'asc',
        })
      )
    }
  }, [dispatch, createUser, editUser, importUsers])

  useEffect(() => {
    if (isSuperUser && !createUser && !editUser && !importUsers) {
      dispatch(
        fetchCustomersList({
          orderField: 'name',
          orderDirection: 'asc',
        })
      )
    }
  }, [dispatch, isSuperUser, createUser, editUser, importUsers])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, record) => (
        <Space>
          {name}
          {record.superuser && <SuperUserTag />}
          {record.provider === 'ResourceServer' && <ResourceServerUserTag />}
        </Space>
      ),
      filterDropdown: (props) => <FilterDropdown {...props} />,
      defaultFilteredValue: queryStringDefaults.name || null,
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      filterDropdown: (props) => <FilterDropdown {...props} />,
      defaultFilteredValue: queryStringDefaults.email || null,
      sorter: true,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      className: isSuperUser ? null : 'fb-column-hidden',
      render: (customer) => <CustomerTag name={customer?.name} />,
      filters: customersList.data?.map((customer) => ({
        text: customer?.name,
        value: customer?._id,
      })),
      defaultFilteredValue: queryStringDefaults.customer || [],
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      render: (roles) => (
        <Row gutter={[0, 4]}>
          {roles.map((role, index) => (
            <Col key={index}>
              <RoleTag name={role.name} />
            </Col>
          ))}
        </Row>
      ),
      filters: rolesList.data?.map((role) => ({
        text: isSuperUser ? `${role.name} - ${role.customer?.name}` : role.name,
        value: role._id,
      })),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) => {
        if (record.provider === 'ResourceServer')
          return <Space>No actions available</Space>

        return (
          <Space size={'small'} split={<Divider type="vertical" />}>
            {record.provider !== 'ResourceServer' && (
              <Popconfirm
                visible={resetPasswordEmail === record.email}
                title={
                  "An email with a reset link will be sent to the user's email. Are you sure?"
                }
                onConfirm={() => resetPasswordHandler(record.email)}
                okButtonProps={{
                  loading: forgotPasswordSending,
                  disabled: forgotPasswordSending,
                }}
                onCancel={() => setResetPasswordEmail(null)}
              >
                <a onClick={() => setResetPasswordEmail(record.email)}>
                  Reset Password
                </a>
              </Popconfirm>
            )}
            {record.provider !== 'ResourceServer' && (
              <a onClick={() => setEditUser(record._id)}>Edit</a>
            )}
            {record._id !== usersMe.data._id && (
              <Popconfirm
                visible={deleteUserId === record._id}
                title={'Are you sure?'}
                onConfirm={() => deleteUserHandler(record._id)}
                okButtonProps={{
                  loading: deletingUser,
                  disabled: deletingUser,
                }}
                onCancel={() => setDeleteUserId(null)}
              >
                <a onClick={() => setDeleteUserId(record._id)}>Delete</a>
              </Popconfirm>
            )}
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <CreateUser
        visible={createUser}
        onDrawerClose={() => setCreateUser(false)}
      />
      <EditUser visible={editUser} onDrawerClose={() => setEditUser(false)} />
      <ImportUsers
        visible={importUsers}
        onModalClose={() => setImportUsers(false)}
        isSuperUser={isSuperUser}
        customersList={customersList}
      />
      <TableContainer
        title={'Users'}
        onBulkDelete={bulkDeleteUsersHandler}
        bulkDeleteLoading={bulkDeletingUsers}
        topBarActions={[
          <Button
            key={'0'}
            icon={<PlusOutlined />}
            type={'primary'}
            shape={'round'}
            size={'large'}
            onClick={() => setCreateUser(true)}
          >
            Add user
          </Button>,
          <Button
            key={'1'}
            icon={<UsergroupAddOutlined />}
            type={'primary'}
            shape={'round'}
            size={'large'}
            onClick={() => setImportUsers(true)}
            ghost
          >
            Import users
          </Button>,
        ]}
        queryStringDefaults={queryStringDefaults}
        tableProps={{
          columns,
          dataSource: usersList.data,
          loading: usersList.loading,
          pagination: {
            total: usersList.total,
          },
          onChange: (pagination, filters, sorter) => {
            getQueryObject(pagination, filters, sorter)
          },
        }}
      />
    </>
  )
}

export default Users
