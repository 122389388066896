import React from 'react'
import moment from 'moment'
import { Col, Row, Statistic, Spin, Card } from 'antd'

const WorkOrderTopInfo = ({ workOrdersDetails }) => {
  if (workOrdersDetails.loading) {
    return (
      <Row justify={'center'}>
        <Spin />
      </Row>
    )
  }

  return (
    <Card title={'Info'}>
      <Row align={'middle'}>
        <Col xs={24} sm={12} lg={6}>
          <Statistic title={'Type'} value={workOrdersDetails.data?.type} />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Statistic
            title={'Priority'}
            value={workOrdersDetails.data?.priority}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Statistic
            title={
              workOrdersDetails.data?.completed ? 'Completed at' : 'Created at'
            }
            value={moment(
              workOrdersDetails.data?.completed
                ? workOrdersDetails.data?.completedAt
                : workOrdersDetails.data?.createdAt
            ).format('MM/DD/Y [at] HH:mm')}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Statistic
            title={'Due at'}
            value={moment(workOrdersDetails.data?.dueAt).format(
              'MM/DD/Y [at] HH:mm'
            )}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default WorkOrderTopInfo
