import { createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'

import * as cloudProvidersApi from '../../axios/cloudProviders'

export const fetchCloudProvidersList = createAsyncThunk(
  'cloudProviders/list',
  async ({ provider, customer, limit, page, order } = {}, thunkAPI) => {
    const response = await cloudProvidersApi.list(
      {
        provider,
        customer,
      },
      {
        limit,
        page,
        order,
        token: thunkAPI.getState().auth.token,
      }
    )
  }
)

export const fetchCloudProvidersDetails = createAsyncThunk(
  'cloudProviders/details',
  async ({ id, customerId }, thunkAPI) => {
    const response = await cloudProvidersApi.details(
      { id, customerId },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    return response
  }
)

export const fetchCloudProvidersMe = createAsyncThunk(
  'cloudProviders/me',
  async (data, thunkAPI) => {
    const response = await cloudProvidersApi.me(data, {
      token: thunkAPI.getState().auth.token,
    })

    return response
  }
)

export const createCloudProvider = createAsyncThunk(
  'cloudProviders/create',
  async (data, thunkAPI) => {
    // Check if the user is a superuser
    const superuser = data.superuser

    delete data.superuser

    const response = await cloudProvidersApi.create(data, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Cloud Provider saved!')

    return { response, superuser }
  }
)

export const updateCloudProviderSettings = createAsyncThunk(
  'cloudProviders/updateSettings',
  async ({ id, settings }, thunkAPI) => {
    const response = await cloudProvidersApi.updateSettings(
      { id, settings },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    message.success('Updated Cloud Provider Settings!')

    return response
  }
)

export const performHealthCheck = createAsyncThunk(
  'cloudProviders/health',
  async (data, thunkAPI) => {
    const response = await cloudProvidersApi.health(data, {
      token: thunkAPI.getState().auth.token,
    })

    return response
  }
)

export const syncWithCloudProvider = createAsyncThunk(
  'cloudProviders/sync',
  async (data, thunkAPI) => {
    const response = await cloudProvidersApi.sync(data, {
      token: thunkAPI.getState().auth.token,
    })

    return response
  }
)

export const deleteCloudProvider = createAsyncThunk(
  'cloudProviders/delete',
  async ({ id, superuser }, thunkAPI) => {
    const response = await cloudProvidersApi.deleteCloudProvider(id, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Signed out from the Cloud Provider!')

    return { response, superuser }
  }
)
