import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import {
  fetchRolesList,
  deleteRole,
  bulkDeleteRoles,
} from '../../store/actions/roles.actions'
import useAuth from '../../hooks/useAuth'
import CreateRole from '../../components/Role/CreateRole'
import EditRole from '../../components/Role/EditRole'
import TableContainer from '../../components/TableContainer'
import useTableActions from '../../hooks/useTableActions'
import CustomerTag from '../../components/Customer/CustomerTag'

const Roles = () => {
  const dispatch = useDispatch()
  const { isSuperUser } = useAuth()
  const {
    getColumnSearchProps,
    getEditDeleteActions,
    getAlphabeticalSorterProps,
  } = useTableActions()
  const [createRole, setCreateRole] = useState(false)
  const [editRole, setEditRole] = useState(null)
  const [rolesList, deletingRole, bulkDeletingRoles] = useSelector((state) => [
    state.roles.list,
    state.roles.deletingRole,
    state.roles.bulkDeletingRoles,
  ])

  const deleteRoleHandler = (id) => {
    dispatch(deleteRole(id))
  }

  const bulkDeleteRolesHandler = (ids) => {
    dispatch(bulkDeleteRoles(ids))
  }

  useEffect(() => {
    dispatch(fetchRolesList())
  }, [dispatch])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      ...getAlphabeticalSorterProps(),
    },
    {
      title: 'Customer',
      dataIndex: ['customer', 'name'],
      className: isSuperUser ? null : 'fb-column-hidden',
      render: (customer) => <CustomerTag name={customer} />,
      ...getAlphabeticalSorterProps(),
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      render: (date) => moment(date).format('MM/DD/Y [at] HH:mm'),
    },
    {
      title: 'UpdatedAt',
      dataIndex: 'updatedAt',
      render: (date) => moment(date).format('MM/DD/Y [at] HH:mm'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      ...getEditDeleteActions(setEditRole, deleteRoleHandler, deletingRole),
    },
  ]

  return (
    <>
      <CreateRole
        visible={createRole}
        onDrawerClose={() => setCreateRole(false)}
      />
      <EditRole visible={editRole} onDrawerClose={() => setEditRole(null)} />
      <TableContainer
        title={'Roles'}
        onBulkDelete={bulkDeleteRolesHandler}
        bulkDeleteLoading={bulkDeletingRoles}
        topBarActions={[
          <Button
            key={'0'}
            type={'primary'}
            size={'large'}
            shape={'round'}
            icon={<PlusOutlined />}
            onClick={() => setCreateRole(true)}
          >
            Add role
          </Button>,
        ]}
        tableProps={{
          columns,
          dataSource: rolesList.data,
          loading: rolesList.loading,
        }}
      />
    </>
  )
}

export default Roles
