import React from 'react'
import _ from 'lodash'
import { List, Space, Typography } from 'antd'

import AssetTypeTag from '../../AssetType/AssetTypeTag'

const AssetList = ({ dataSource, projectAssetTypes = null, onAssetRemove }) => {
  return (
    <List
      dataSource={dataSource}
      renderItem={(item) => {
        let assetTypeIndex

        if (projectAssetTypes) {
          assetTypeIndex = _.findIndex(projectAssetTypes, [
            '_id',
            item.assetType,
          ])
        }

        return (
          <List.Item
            actions={[
              onAssetRemove && (
                <a onClick={() => onAssetRemove(item.foreignId)}>Remove</a>
              ),
            ]}
          >
            <Space size={'large'}>
              <AssetTypeTag
                name={
                  projectAssetTypes
                    ? projectAssetTypes[assetTypeIndex].name
                    : item.assetType.name
                }
              />
              <Typography.Text>{item.foreignId}</Typography.Text>
            </Space>
          </List.Item>
        )
      }}
    />
  )
}

export default AssetList
