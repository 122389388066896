import React from 'react'
import _ from 'lodash'
import { Col, Form, Input, Row, Select, Switch } from 'antd'

import RoleTag from '../../Role/RoleTag'
import GroupTag from '../../Group/GroupTag'

const UserForm = ({
  form,
  onFinish,
  onValuesChange = () => {},
  onFieldsChange = () => {},
  rolesList,
  groupsList,
  customersList,
  defaultCustomer,
  showSuperUserFields = false,
  isUserMe = false,
}) => {
  return (
    <Form
      layout={'vertical'}
      form={form}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
      initialValues={{
        customer: defaultCustomer,
      }}
    >
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name={'name'}
            rules={[{ required: true, message: 'This field is required!' }]}
            label={'Name'}
          >
            <Input placeholder={'Please enter users first and last name'} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={'email'}
            rules={[
              { required: true, message: 'This field is required!' },
              {
                type: 'email',
                message: 'Please provide a valid email address!',
              },
            ]}
            label={'Email'}
          >
            <Input placeholder={'Please provide the users email address'} />
          </Form.Item>
        </Col>
      </Row>
      {showSuperUserFields && (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name={'customer'}
              label={'Customer'}
              tooltip={'Super User setting'}
            >
              <Select
                loading={customersList.loading}
                disabled={customersList.loading}
                options={_.orderBy(
                  customersList.data.map((customer) => ({
                    label: customer.name,
                    value: customer._id,
                  })),
                  ['label'],
                  ['asc']
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name={'roles'}
            label={'Roles'}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value.length > 0) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error('At least one role has to be selected!')
                  )
                },
              }),
            ]}
          >
            <Select
              loading={rolesList.loading}
              disabled={rolesList.loading}
              options={_.sortBy(rolesList.data, ['name'], ['asc']).map(
                (role) => ({
                  label: role.name,
                  value: role._id,
                  disabled: role.name === 'Administrator' && isUserMe,
                })
              )}
              mode={'multiple'}
              tagRender={({ label, value, closable, onClose }) => (
                <RoleTag name={label} closable={closable} onClose={onClose} />
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={'groups'} label={'Groups'}>
            <Select
              loading={groupsList.loading}
              disabled // TODO change this when roles and groups are implemented
              // disabled={groupsList.loading}
              options={groupsList.data.map((group) => ({
                label: group.name,
                value: group._id,
              }))}
              mode={'multiple'}
              tagRender={({ label, value, closable, onClose }) => (
                <GroupTag name={label} closable={closable} onClose={onClose} />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      {/*{showSuperUserFields && (*/}
      {/*  <Row gutter={16}>*/}
      {/*    <Col xs={24} md={12}>*/}
      {/*      <Form.Item*/}
      {/*        name={'superuser'}*/}
      {/*        label={'Super User'}*/}
      {/*        valuePropName={'checked'}*/}
      {/*        tooltip={'Super User setting'}*/}
      {/*      >*/}
      {/*        <Switch />*/}
      {/*      </Form.Item>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*)}*/}
    </Form>
  )
}

export default UserForm
