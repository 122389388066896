import React from 'react'
import moment from 'moment'
import { Tooltip } from 'antd'
import {
  CheckCircleFilled,
  ToolFilled,
  ClockCircleOutlined,
} from '@ant-design/icons'

import classes from './StatusIcon.less'

const WorkOrderStatusIcon = ({ completed, startAt }) => {
  if (startAt && new Date() < new Date(startAt)) {
    return (
      <Tooltip
        title={`Pending, will be available since ${moment(startAt).format(
          'MM/DD/Y [at] HH:mm'
        )}`}
      >
        <ClockCircleOutlined className={classes.PrimaryColor} />
      </Tooltip>
    )
  }

  if (completed) {
    return (
      <Tooltip title={'Completed'}>
        <CheckCircleFilled className={classes.SuccessColor} />
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title={'In progress'}>
        <ToolFilled className={classes.PrimaryColor} />
      </Tooltip>
    )
  }
}

export default WorkOrderStatusIcon
