import React from 'react'
import moment from 'moment'
import { Card, Col, Result, Row, Badge, Collapse, Descriptions } from 'antd'
import { SmileOutlined } from '@ant-design/icons'

import './WorkOrderCompletedSection.no-module.less'
import classes from './WorkOrderCompletedSection.less'
import UserTag from '../../User/UserTag'
import ProjectTag from '../../Project/ProjectTag'
import AssignedToTags from '../AssignedToTags'

const WorkOrderCompletedSection = ({ completionStatus, workOrdersDetails }) => {
  if (completionStatus === false || workOrdersDetails.loading) return null

  const hours = Math.floor(workOrdersDetails.data?.timeSpent / 60)
  const minutes = workOrdersDetails.data?.timeSpent % 60

  return (
    <Card>
      <Result
        className={'custom-result'}
        status={'success'}
        icon={<SmileOutlined />}
        title={`Work Order completed`}
      >
        <div className={classes.InsideContainer}>
          <Row gutter={[8, 16]} justify={'center'}>
            <Col xs={20} sm={18} md={14} lg={10}>
              <Descriptions column={1}>
                <Descriptions.Item label={'Time taken'}>
                  {`${hours}h and ${minutes}min`}
                </Descriptions.Item>
                <Descriptions.Item label={'Completion notes'}>
                  <span className={classes.ShowEntersAndTabs}>
                    {workOrdersDetails.data?.completionNotes}
                  </span>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}>
              <Collapse ghost>
                <Collapse.Panel header={'Show more'} key={'1'}>
                  <Descriptions
                    column={{ xs: 1, md: 2 }}
                    layout={'vertical'}
                    bordered
                  >
                    <Descriptions.Item label={'Name'} span={1}>
                      {workOrdersDetails.data?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Project'}>
                      <ProjectTag name={workOrdersDetails.data?.project.name} />
                    </Descriptions.Item>
                    <Descriptions.Item label={'Created at'}>
                      {moment(workOrdersDetails.data?.createdAt).format(
                        'MM/DD/Y [at] HH:mm'
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Last edit at'}>
                      {moment(workOrdersDetails.data?.updatedAt).format(
                        'MM/DD/Y [at] HH:mm'
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Assigned to'} span={2}>
                      <AssignedToTags
                        users={workOrdersDetails.data?.assignedToUsers}
                        groups={workOrdersDetails.data?.assignedToGroups}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label={'Status'} span={2}>
                      <Badge status={'success'} text={'Completed'} />
                    </Descriptions.Item>
                    <Descriptions.Item label={'Completed at'}>
                      {moment(workOrdersDetails.data?.completedAt).format(
                        'MM/DD/Y [at] HH:mm'
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Completed by'}>
                      <UserTag
                        name={workOrdersDetails.data?.completedBy?.name}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={'Completed in'}
                      span={2}
                    >{`${hours}h and ${minutes}min`}</Descriptions.Item>
                    <Descriptions.Item label={'Completion notes'} span={2}>
                      <span className={classes.ShowEntersAndTabs}>
                        {workOrdersDetails.data?.completionNotes
                          ? workOrdersDetails.data?.completionNotes
                          : 'No completion notes.'}
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        </div>
      </Result>
    </Card>
  )
}

export default WorkOrderCompletedSection
