import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Row, Col, Divider, Button } from 'antd'

import { updateCustomer } from '../../../store/actions/customers.actions'
import CustomerForm from '../../Customer/CustomerForm'

const SuperUser = ({ customer }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [updatingCustomer] = useSelector((state) => [
    state.customers.updatingCustomer,
  ])
  const [isFormTouched, setIsFormTouched] = useState(false)

  const customerData = customer.data
  useEffect(() => {
    if (customerData) {
      form.setFieldsValue({
        ...customerData,
        // this object has to be moment encapsulated
        validUntil: customerData?.validUntil
          ? moment(customerData.validUntil)
          : null,
      })
    }
  }, [form, customerData])

  const updateCustomerHandler = (values) => {
    dispatch(
      updateCustomer({
        id: customerData._id,
        data: {
          ...customerData,
          ...values,
        },
      })
    ).then((thunkResult) => {
      if (thunkResult.meta.requestStatus !== 'rejected') {
        setIsFormTouched(false)
      }
    })
  }

  return (
    <>
      <CustomerForm
        form={form}
        onFinish={updateCustomerHandler}
        onValuesChange={() => setIsFormTouched(true)}
        size={'large'}
        minLicenseNumber={customerData?.licensesUsed}
        showResourceServerUrlDefault={
          customerData?.authProvider?.authType === 'resourceserver'
        }
      />
      <Divider />
      <Row gutter={[8, 16]} justify={'end'}>
        <Col>
          <Button
            type={'primary'}
            shape={'round'}
            size={'large'}
            disabled={updatingCustomer || !isFormTouched}
            loading={updatingCustomer}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default SuperUser
