import React from 'react'
import { Tag } from 'antd'
import { CrownOutlined } from '@ant-design/icons'

const SuperUserTag = ({ ...props }) => (
  <Tag color={'purple'} icon={<CrownOutlined />} {...props}>
    SuperUser
  </Tag>
)

export default SuperUserTag
