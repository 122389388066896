import axiosBackendInstance from './backendInstance'

export const list = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get('/fb/user', {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
    params: {
      name: data.name,
      email: data.email,
      customer: data.customer,
      roles: data.roles,
      limit: requestProps.limit,
      page: requestProps.page,
      orderField: requestProps.orderField,
      orderDirection: requestProps.orderDirection,
    },
  })

  return responseData
}

export const details = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    `/fb/user/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const me = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get('/fb/user/me', {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
  })

  return responseData
}

export const create = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.post(
    '/fb/user',
    {
      ...data,
      baseUrl: window.location.origin,
    },
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const update = async ({ id, data }, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.put(
    `/fb/user/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const deleteUser = async (id, requestProps) => {
  await axiosBackendInstance.delete(`/fb/user/${id}`, {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
  })

  return id
}

export const bulkDeleteUsers = async (data, requestProps) => {
  await axiosBackendInstance.post('/fb/user/bulkDelete', data, {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
  })

  return data
}

export const forgotPassword = async (data, requestProps) => {
  await axiosBackendInstance.post(
    '/fb/user/forgotPassword',
    {
      baseUrl: window.location.origin,
      email: data.email,
    },
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return true
}

export const changePassword = async (data, requestProps) => {
  await axiosBackendInstance.post(
    `/fb/user/changePassword/${data.id}`,
    {
      oldPassword: data.prevPassword,
      newPassword: data.newPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return true
}

export const resetPassword = async (data, requestProps) => {
  await axiosBackendInstance.post(
    `/fb/user/resetPassword/${data.token}`,
    {
      newPassword: data.newPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return true
}
