import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Space, Form } from 'antd'

import { createAssetType } from '../../../store/actions/assetTypes.actions'

import ModifyDrawer from '../../ModifyDrawer'
import AssetTypeForm from '../AssetTypeForm'

const CreateAssetType = ({ visible, onDrawerClose }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [creatingAssetType] = useSelector((state) => [
    state.assetTypes.creatingAssetType,
  ])

  const createAssetTypeHandler = (values) => {
    dispatch(createAssetType(values)).then(() => {
      form.resetFields()
      onDrawerClose()
    })
  }

  return (
    <ModifyDrawer
      title={'Create Asset Type'}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}>Cancel</Button>
          <Button
            type={'primary'}
            onClick={() => form.submit()}
            loading={creatingAssetType}
            disabled={creatingAssetType}
          >
            Create
          </Button>
        </Space>
      }
      visible={visible}
      onDrawerClose={onDrawerClose}
    >
      <AssetTypeForm form={form} onFinish={createAssetTypeHandler} />
    </ModifyDrawer>
  )
}

export default CreateAssetType
