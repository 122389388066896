import React from 'react'
import { Card, Image, Row } from 'antd'
import {
  DownloadOutlined,
  SettingOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

import classes from './File.less'
import fileImage from '../../../assets/images/file128.png'

const File = ({ name, onDelete }) => {
  return (
    <Card
      hoverable
      cover={
        <Row justify={'center'} style={{ marginTop: '10px' }}>
          <Image src={fileImage} preview={false} width={'128px'} />
        </Row>
      }
      actions={[
        <DownloadOutlined key={'download'} />,
        <SettingOutlined key={'setting'} />,
        <DeleteOutlined
          key={'delete'}
          className={classes.DeleteIcon}
          onClick={onDelete}
        />,
      ]}
    >
      <Card.Meta title={name} />
    </Card>
  )
}

export default File
