import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Row, Col, Input, Button } from 'antd'

import { resetPassword } from '../../store/actions/users.actions'
import userPasswordRules from '../../constants/userPasswordRules'
import Logo from '../../components/Logo'

const ResetPassword = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { token } = useParams()
  const [resettingPassword] = useSelector((state) => [
    state.users.resettingPassword,
  ])

  const resetPasswordHandler = (values) => {
    dispatch(
      resetPassword({
        token,
        newPassword: values.newPassword,
      })
    ).then(() => {
      history.push('/signin')
    })
  }

  return (
    <>
      <Row gutter={[8, 16]} justify={'center'}>
        <Logo />
      </Row>
      <Form layout={'vertical'} onFinish={resetPasswordHandler}>
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <Form.Item
              name={'newPassword'}
              label={'Provide a new password!'}
              rules={userPasswordRules}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'end'}>
          <Col>
            <Button
              type={'primary'}
              shape={'round'}
              loading={resettingPassword}
              disabled={resettingPassword}
              htmlType={'submit'}
            >
              Reset the password
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ResetPassword
