import React from 'react'
import _ from 'lodash'
import { Table, Space, Empty, Image } from 'antd'

import classes from './ResourcesListView.less'
import fileIcon from '../../../assets/images/file24.png'
import folderIcon from '../../../assets/images/folder24.png'
import GroupTag from '../../Group/GroupTag'

const ResourcesListView = ({
  resources = [],
  groups = [],
  loading = false,
  changePrefix = () => {},
}) => {
  const reducedGroups = _.reduce(
    groups,
    (result, value) => {
      result[value._id] = value.name

      return result
    },
    {}
  )

  const columns = [
    {
      title: 'Type',
      dataIndex: 'resourceType',
      align: 'center',
      width: 10,
      sorter: (a, b) => {
        if (a.type !== 'file') return 1
        return -1
      },
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'descend',
      render: (type) => {
        if (type === 'file')
          return <Image src={fileIcon} preview={false} width={24} />
        return <Image src={folderIcon} preview={false} width={24} />
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Accessible by',
      dataIndex: 'permittedGroups',
      render: (access, record) => {
        if (!record.permittedGroups) return

        return record.permittedGroups?.map((permission) => (
          <GroupTag key={permission} name={reducedGroups[permission]} />
        ))
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'right',
      render: (actions, record) => (
        <Space>
          {record.type === 'file' && <a>Download</a>}
          <a>Edit</a>
          <a>Delete</a>
        </Space>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={resources}
      loading={loading}
      locale={{
        emptyText: (
          <Empty
            style={{ margin: '60px 0' }}
            description={<span>Empty</span>}
          />
        ),
      }}
      rowClassName={classes.Row}
      onRow={(record, rowIndex) => ({
        onClick: () => {
          if (record.type === 'folder') changePrefix(record.resourcePath)
        },
      })}
    />
  )
}

export default ResourcesListView
