import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row, Col, PageHeader, Spin, Space } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

import {
  fetchCustomersDetails,
  fetchCustomersMe,
} from '../../store/actions/customers.actions'

import useAuth from '../../hooks/useAuth'
import SuperUser from '../../components/Settings/SuperUser'
import General from '../../components/Settings/General'
import Resources from '../../components/Settings/Resources'
import WorkOrderManagement from '../../components/Settings/WorkOrderManagement'

const Settings = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tab, id } = useParams()
  const { isSuperUser } = useAuth()
  const [usersMe, customersMe, customersDetails] = useSelector((state) => [
    state.users.me,
    state.customers.me,
    state.customers.details,
  ])

  let tabList = [
    {
      tab: 'Organization',
      key: 'general',
    },
    {
      tab: 'Resources',
      key: 'resource',
    },
    // {
    //   tab: 'Work Order Management Settings',
    //   key: 'wom',
    // },
  ]

  // If the user is a super user, show this tab
  if (isSuperUser) {
    tabList = [
      {
        tab: 'Super User',
        key: 'superUser',
      },
      ...tabList,
    ]
  }

  const usersMeData = usersMe.data
  useEffect(() => {
    if (id === 'me' || id === usersMeData.customer._id) {
      dispatch(fetchCustomersMe())
    } else {
      dispatch(fetchCustomersDetails(id))
    }
  }, [id, dispatch, usersMeData])

  // If an id is provided, show settings for this id
  const customer =
    id === 'me' || id === usersMeData.customer._id
      ? customersMe
      : customersDetails

  let currentSettings = null
  if (tab === 'general') {
    currentSettings = (
      <General customer={customer} showSuperUserFields={isSuperUser} />
    )
  } else if (tab === 'superUser' && isSuperUser) {
    currentSettings = <SuperUser customer={customer} />
  } else if (tab === 'resource') {
    currentSettings = <Resources customer={customer} />
  } else if (tab === 'wom') {
    currentSettings = <WorkOrderManagement />
  } else {
    history.replace(`/settings/general/${id}`)
  }

  return (
    <>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <Spin size={'large'} spinning={customer.loading}>
            <PageHeader title={'Settings'} ghost={false} />
          </Spin>
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <Card
            activeTabKey={tab}
            tabList={tabList}
            onTabChange={(key) => history.push(`/settings/${key}/${id}`)}
          >
            {currentSettings}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Settings
