import { createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'

import * as resourcesApi from '../../axios/resources'

export const fetchResourcesList = createAsyncThunk(
  'resources/list',
  async ({ prefix } = {}, thunkAPI) => {
    const response = await resourcesApi.list(
      {
        prefix,
      },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    return response
  }
)

export const fetchResourcesDetails = createAsyncThunk(
  'resources/details',
  async ({ id }, thunkAPI) => {
    const response = await resourcesApi.details(
      { id },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    return response
  }
)

export const createFile = createAsyncThunk(
  'resources/createFile',
  async (data, thunkAPI) => {
    const response = await resourcesApi.createFile(data, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('File created!')

    return response
  }
)

export const createFolder = createAsyncThunk(
  'resources/createFolder',
  async (data, thunkAPI) => {
    const response = await resourcesApi.createFolder(data, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Folder created!')

    return response
  }
)

export const updateResource = createAsyncThunk(
  'resources/update',
  async ({ id, data }, thunkAPI) => {
    const response = await resourcesApi.update(
      { id, data },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    return response
  }
)

export const deleteResource = createAsyncThunk(
  'resources/delete',
  async ({ key, id }, thunkAPI) => {
    const response = await resourcesApi.deleteResource(
      { key, id },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    message.success('Resource removed!')

    return response
  }
)
