import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import {
  fetchGroupsList,
  deleteGroup,
  bulkDeleteGroups,
} from '../../store/actions/groups.actions'
import useAuth from '../../hooks/useAuth'
import CreateGroup from '../../components/Group/CreateGroup'
import EditGroup from '../../components/Group/EditGroup'
import TableContainer from '../../components/TableContainer'
import useTableActions from '../../hooks/useTableActions'
import CustomerTag from '../../components/Customer/CustomerTag'

const Groups = () => {
  const dispatch = useDispatch()
  const { isSuperUser } = useAuth()
  const {
    getColumnSearchProps,
    getEditDeleteActions,
    getAlphabeticalSorterProps,
  } = useTableActions()
  const [createGroup, setCreateGroup] = useState(false)
  const [editGroup, setEditGroup] = useState(null)
  const [
    groupsList,
    deletingGroup,
    bulkDeletingGroups,
  ] = useSelector((state) => [
    state.groups.list,
    state.groups.deletingGroup,
    state.groups.bulkDeletingGroups,
  ])

  const deleteGroupHandler = (id) => {
    dispatch(deleteGroup(id))
  }

  const bulkDeleteGroupsHandler = (ids) => {
    dispatch(bulkDeleteGroups(ids))
  }

  useEffect(() => {
    dispatch(fetchGroupsList())
  }, [dispatch])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      ...getAlphabeticalSorterProps(),
    },
    {
      title: 'Customer',
      dataIndex: ['customer', 'name'],
      className: isSuperUser ? null : 'fb-column-hidden',
      render: (customer) => <CustomerTag name={customer} />,
      ...getAlphabeticalSorterProps(),
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      render: (date) => moment(date).format('MM/DD/Y [at] HH:mm'),
    },
    {
      title: 'UpdatedAt',
      dataIndex: 'updatedAt',
      render: (date) => moment(date).format('MM/DD/Y [at] HH:mm'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      ...getEditDeleteActions(setEditGroup, deleteGroupHandler, deletingGroup),
    },
  ]

  return (
    <>
      <CreateGroup
        visible={createGroup}
        onDrawerClose={() => setCreateGroup(false)}
      />
      <EditGroup visible={editGroup} onDrawerClose={() => setEditGroup(null)} />
      <TableContainer
        title={'Groups'}
        onBulkDelete={bulkDeleteGroupsHandler}
        bulkDeleteLoading={bulkDeletingGroups}
        topBarActions={[
          <Button
            key={'0'}
            type={'primary'}
            size={'large'}
            shape={'round'}
            icon={<PlusOutlined />}
            onClick={() => setCreateGroup(true)}
          >
            Add group
          </Button>,
        ]}
        tableProps={{
          columns,
          dataSource: groupsList.data,
          loading: groupsList.loading,
        }}
      />
    </>
  )
}

export default Groups
