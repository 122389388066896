import { createAsyncThunk } from '@reduxjs/toolkit'

import * as groupsApi from '../../axios/groups'
import { showMessage } from './messages.actions'

export const fetchGroupsList = createAsyncThunk(
  'groups/list',
  async (
    { customer, name, page, limit, orderField, orderDirection } = {},
    thunkAPI
  ) => {
    const response = await groupsApi.list(
      { customer, name },
      {
        page,
        limit,
        orderField,
        orderDirection,
        token: thunkAPI.getState().auth.token,
      }
    )

    return response
  }
)

export const fetchGroupsDetails = createAsyncThunk(
  'groups/details',
  async (id, thunkAPI) => {
    const response = await groupsApi.details(
      { id },
      { token: thunkAPI.getState().auth.token }
    )

    return response
  }
)

export const createGroup = createAsyncThunk(
  'groups/create',
  async (data, thunkAPI) => {
    const response = await groupsApi.create(data, {
      token: thunkAPI.getState().auth.token,
    })

    thunkAPI.dispatch(
      showMessage({ type: 'success', config: { content: 'Group created!' } })
    )

    return response
  }
)

export const updateGroup = createAsyncThunk(
  'groups/update',
  async ({ id, data }, thunkAPI) => {
    const response = await groupsApi.update(
      {
        id,
        data,
      },
      { token: thunkAPI.getState().auth.token }
    )

    thunkAPI.dispatch(
      showMessage({ type: 'success', config: { content: 'Group updated!' } })
    )

    return response
  }
)

export const deleteGroup = createAsyncThunk(
  'groups/delete',
  async (id, thunkAPI) => {
    const response = await groupsApi.deleteGroup(id, {
      token: thunkAPI.getState().auth.token,
    })

    thunkAPI.dispatch(
      showMessage({ type: 'success', config: { content: 'Group deleted!' } })
    )

    return response
  }
)

export const bulkDeleteGroups = createAsyncThunk(
  'groups/bulkDelete',
  async (ids, thunkAPI) => {
    const response = await groupsApi.bulkDeleteGroups(ids, {
      token: thunkAPI.getState().auth.token,
    })

    thunkAPI.dispatch(
      showMessage({ type: 'success', config: { content: 'Groups deleted!' } })
    )

    return response
  }
)
