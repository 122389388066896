import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Space, Button, Spin } from 'antd'

import {
  fetchRolesDetails,
  updateRole,
} from '../../../store/actions/roles.actions'
import { fetchUsersList } from '../../../store/actions/users.actions'
import ModifyDrawer from '../../ModifyDrawer'
import RoleForm from '../RoleForm'

const EditRole = ({ visible, onDrawerClose }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [rolesDetails, updatingRole, usersList] = useSelector((state) => [
    state.roles.details,
    state.roles.updatingRole,
    state.users.list,
  ])

  useEffect(() => {
    if (visible) {
      dispatch(fetchRolesDetails(visible))
    }
  }, [dispatch, visible])

  const rolesCustomerId = rolesDetails.data?.customer._id
  useEffect(() => {
    if (visible && rolesCustomerId) {
      dispatch(fetchUsersList({ customer: rolesCustomerId }))
    }
  }, [dispatch, visible, rolesCustomerId])

  const rolesDetailsData = rolesDetails.data
  useEffect(() => {
    if (rolesDetailsData) {
      form.setFieldsValue({
        name: rolesDetailsData.name,
        users: rolesDetailsData.users,
        actions: rolesDetailsData.actions,
      })
    }
  }, [rolesDetailsData, form])

  const updateRoleHandler = (values) => {
    dispatch(
      updateRole({
        id: visible,
        data: {
          ...rolesDetails.data,
          ...values,
        },
      })
    ).then(() => {
      onDrawerClose()
    })
  }

  return (
    <ModifyDrawer
      title={'Update role'}
      visible={visible}
      onDrawerClose={onDrawerClose}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}>Cancel</Button>
          <Button
            type={'primary'}
            onClick={() => form.submit()}
            loading={updatingRole}
            disabled={updatingRole}
          >
            Save
          </Button>
        </Space>
      }
    >
      {rolesDetails.loading ? (
        <Spin />
      ) : (
        <RoleForm
          form={form}
          onFinish={updateRoleHandler}
          usersList={usersList}
          showSuperUserFields={false}
        />
      )}
    </ModifyDrawer>
  )
}

export default EditRole
