import axios from 'axios'
import qs from 'qs'
import { message } from 'antd'

const axiosBackendInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  paramsSerializer: (params) => qs.stringify(params),
})

axiosBackendInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Not a Connection Error
    if (error.response) {
      message.error(
        error.response?.data?.message ||
          error.response?.statusText ||
          'Connection with the server lost. Please try again.'
      )

      return Promise.reject(error)
    }

    let requestParams = qs.parse(error.config.params)
    let errorCount = +requestParams['network_error_count']
    // Check what error number
    if (errorCount && errorCount > 3) {
      message.error('Connection with the server lost. Please try again.')

      return Promise.reject(error)
    } else {
      console.log(
        `There was a connectivity problem, retrying for the ${errorCount} time.`
      )

      if (errorCount) {
        errorCount++
      } else {
        errorCount = 1
      }

      requestParams = {
        ...requestParams,
        network_error_count: errorCount,
      }

      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve(
              axiosBackendInstance({ ...error.config, params: requestParams })
            ),
          1500
        )
      )
    }
  }
)

export default axiosBackendInstance
