import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { message } from 'antd'

import {
  fetchAssetTypesList,
  fetchAssetTypesDetails,
  createAssetType,
  updateAssetType,
  deleteAssetType,
} from '../actions/assetTypes.actions'

const initialState = {
  list: {
    loading: false,
    data: [],
  },
  details: {
    loading: false,
    data: null,
  },
  creatingAssetType: false,
  updatingAssetType: false,
  deletingAssetType: false,
}

const assetTypesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAssetTypesList.pending, (state, action) => {
      state.list.data = []
      state.list.loading = true
    })
    .addCase(fetchAssetTypesList.fulfilled, (state, action) => {
      state.list.data = action.payload
      state.list.loading = false
    })
    .addCase(fetchAssetTypesDetails.pending, (state, action) => {
      state.details.data = null
      state.details.loading = true
    })
    .addCase(fetchAssetTypesDetails.fulfilled, (state, action) => {
      state.details.data = action.payload
      state.details.loading = false
    })
    .addCase(createAssetType.pending, (state, action) => {
      state.creatingAssetType = true
    })
    .addCase(createAssetType.fulfilled, (state, action) => {
      state.list.data.push(action.payload)

      state.creatingAssetType = false
    })
    .addCase(updateAssetType.pending, (state, action) => {
      state.updatingAssetType = true
    })
    .addCase(updateAssetType.fulfilled, (state, action) => {
      const updatedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[updatedIndex] = action.payload
      // Update the selected item
      state.details.data = action.payload

      state.updatingAssetType = false
    })
    .addCase(deleteAssetType.pending, (state, action) => {
      state.deletingAssetType = true
    })
    .addCase(deleteAssetType.fulfilled, (state, action) => {
      const deletedIndex = _.findIndex(state.list.data, ['_id', action.payload])

      // Delete from the list
      state.list.data.splice(deletedIndex, 1)

      // Delete if selected
      if (state.details.data?._id === action.payload) {
        state.details.data = null
      }

      state.deletingAssetType = false
    })
})

export default assetTypesReducer
