import React from 'react'
import moment from 'moment'
import {
  List,
  Comment,
  Input,
  Row,
  Col,
  Form,
  Divider,
  Typography,
  Card,
  Button,
} from 'antd'

import classes from './CommentSection.less'

const CommentSection = ({
  comments = [],
  loading = false,
  maxCommentLength = 300,
  postComment = () => {},
  postingComment = false,
}) => {
  const [form] = Form.useForm()

  return (
    <Card>
      <Typography.Title level={3}>Comments</Typography.Title>
      <div className={classes.Container}>
        <List
          itemLayout={'horizontal'}
          locale={{ emptyText: 'No comments' }}
          dataSource={comments}
          loading={loading}
          renderItem={(item) => (
            <Card
              className={classes.CommentCard}
              bodyStyle={{ padding: '0 3px' }}
            >
              <Comment
                author={item.author.name}
                content={item.content}
                datetime={
                  <span>
                    {moment(item.createdAt).format('MM/DD/Y [at] HH:mm')}
                  </span>
                }
              />
            </Card>
          )}
        />
      </div>
      <Divider style={{ marginTop: 0 }} />
      <Form
        layout={'vertical'}
        form={form}
        onFinish={(values) => {
          form.resetFields()
          postComment(values)
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name={'content'}
              label={'Write a comment'}
              rules={[
                { required: true, message: 'A comment can not be empty' },
              ]}
              colon
            >
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 4 }}
                maxLength={maxCommentLength}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'begin'}>
          <Button
            loading={postingComment}
            disabled={postingComment}
            type={'primary'}
            htmlType={'submit'}
          >
            Post
          </Button>
        </Row>
      </Form>
    </Card>
  )
}

export default CommentSection
