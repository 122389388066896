import React from 'react'
import { Form, Row, Col, Input, Select } from 'antd'

import UserTag from '../../User/UserTag'

const GroupForm = ({
  form,
  onFinish,
  usersList,
  customersList,
  defaultCustomer,
  showSuperUserFields = false,
  onFieldsChange = () => {},
}) => {
  return (
    <Form
      layout={'vertical'}
      form={form}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      initialValues={{ customer: defaultCustomer }}
    >
      <Row guter={16}>
        <Col span={24}>
          <Form.Item
            name={'name'}
            rules={[{ required: true, message: 'This field is required!' }]}
            label={'Group name'}
          >
            <Input placeholder={'Please provide a name for the group'} />
          </Form.Item>
        </Col>
      </Row>
      {showSuperUserFields && (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name={'customer'}
              label={'Customer'}
              tooltip={'Super User setting'}
            >
              <Select
                loading={customersList.loading}
                disabled={customersList.disabled}
                options={customersList.data.map((customer) => ({
                  label: customer.name,
                  value: customer._id,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name={'users'} label={'Users'}>
            <Select
              loading={usersList.loading}
              disabled={usersList.loading}
              options={usersList.data.map((user) => ({
                label: user.name,
                value: user._id,
              }))}
              tagRender={({ label, value, closable, onClose }) => (
                <UserTag name={label} closable={closable} onClose={onClose} />
              )}
              mode={'multiple'}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default GroupForm
