import React from 'react'
import { Tag } from 'antd'
import { SolutionOutlined } from '@ant-design/icons'

const RoleTag = ({ name, ...props }) => (
  <Tag color={'cyan'} icon={<SolutionOutlined />} {...props}>
    {name}
  </Tag>
)

export default RoleTag
