import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Row, Col, Input, InputNumber, Divider, Button } from 'antd'

import { updateSettings } from '../../../store/actions/customers.actions'

const General = ({ customer, showSuperUserFields = false }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [updatingSettings] = useSelector((state) => [
    state.customers.updatingSettings,
  ])
  const [isFormTouched, setIsFormTouched] = useState(false)

  const customerData = customer.data
  useEffect(() => {
    if (customerData) {
      form.setFieldsValue(customerData.settings)
    }
  }, [form, customerData])

  const updateGeneralSettingsHandler = (values) => {
    dispatch(updateSettings({ id: customer.data._id, data: values })).then(
      (thunkResult) => {
        if (thunkResult.meta.requestStatus !== 'rejected') {
          setIsFormTouched(false)
        }
      }
    )
  }

  return (
    <>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={updateGeneralSettingsHandler}
        onValuesChange={() => setIsFormTouched(true)}
        size={'large'}
      >
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Form.Item
              name={'TimeInBackgroundAllowedBeforeLogout'}
              label={'Time in background allowed before logout (in minutes)'}
              rules={[
                {
                  type: 'number',
                  message: 'This field must be a number!',
                },
              ]}
            >
              <InputNumber
                defaultValue={5}
                min={1}
                precision={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          {showSuperUserFields && (
            <Col md={12} xs={24}>
              <Form.Item
                name={'TimeAllowedBetweenApprovalChecks'}
                label={'Time allowed between approval checks (in hours)'}
                rules={[
                  {
                    type: 'number',
                    message: 'This field must be a number!',
                  },
                ]}
                tooltip={'Super User Setting'}
              >
                <InputNumber
                  defaultValue={1}
                  min={1}
                  precision={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Divider />
        <Row gutter={[8, 16]} justify={'end'}>
          <Col>
            <Button
              type={'primary'}
              shape={'round'}
              disabled={updatingSettings || !isFormTouched}
              loading={updatingSettings}
              htmlType={'submit'}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default General
