import React from 'react'
import { Drawer, Grid } from 'antd'

import classes from './ModifyDrawer.less'

const ModifyDrawer = ({ title, footer, visible, onDrawerClose, children }) => {
  const screens = Grid.useBreakpoint()
  const isXs = screens.xs && !screens.sm,
    isSm = screens.sm && !screens.md

  return (
    <Drawer
      title={title}
      width={isSm || isXs ? '90%' : 720}
      footer={footer}
      onClose={onDrawerClose}
      visible={visible}
    >
      {children}
    </Drawer>
  )
}

export default ModifyDrawer
