import React from 'react'
import { Form, Row, Col, Input, Select, Button } from 'antd'

const AssetTypeForm = ({ form, onFinish }) => {
  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name={'name'}
            label={'Name'}
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name={'description'} label={'Description'}>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 3 }}
              maxLength={300}
              showCount
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={'tableName'}
            label={'Table Name'}
            rules={[{ required: true, message: 'This field is required!' }]}
            tooltip={'The name of the table that contains the asset'}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'idColumnName'}
            label={'ID Column Name'}
            rules={[{ required: true, message: 'This field is required!' }]}
            tooltip={"The name of the column containing assets ID's"}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default AssetTypeForm
