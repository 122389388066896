import React from 'react'
import _ from 'lodash'
import { Row, Col, List, Space, Form, Select, Divider } from 'antd'
import { GlobalOutlined, UserOutlined } from '@ant-design/icons'
import RoleTag from '../../../Role/RoleTag'
import GroupTag from '../../../Group/GroupTag'

const ImportUsersForm = ({
  form,
  onFinish,
  onFieldsChange,
  rolesList,
  groupsList,
  defaultCustomer,
  customersList,
  usersToImport = [],
  isSuperUser,
}) => {
  if (_.isEmpty(usersToImport)) return null

  return (
    <>
      <Row justify={'center'}>
        <Col span={22}>
          <List
            dataSource={usersToImport}
            renderItem={(item) => (
              <List.Item>
                <Space>
                  <>
                    <UserOutlined />
                    {item.name}
                  </>
                  <>
                    <GlobalOutlined />
                    {item.email}
                  </>
                </Space>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Divider />
      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        initialValues={{
          customer: defaultCustomer,
        }}
      >
        {isSuperUser && (
          <Row>
            <Col span={24}>
              <Form.Item
                name={'customer'}
                label={'Customer'}
                tooltip={'Super User setting'}
              >
                <Select
                  loading={customersList.loading}
                  disabled={customersList.loading}
                  options={_.orderBy(
                    customersList.data.map((customer) => ({
                      label: customer.name,
                      value: customer._id,
                    })),
                    ['label'],
                    ['asc']
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={[10, 0]}>
          <Col span={12}>
            <Form.Item
              name={'roles'}
              label={'Roles'}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value.length > 0) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('At least one role has to be selected!')
                    )
                  },
                }),
              ]}
            >
              <Select
                loading={rolesList.loading}
                disabled={rolesList.loading}
                options={_.sortBy(rolesList.data, ['name'], ['asc']).map(
                  (role) => ({
                    label: role.name,
                    value: role._id,
                    //disabled: role.name === 'Administrator', // && isUserMe,
                  })
                )}
                mode={'multiple'}
                tagRender={({ label, value, closable, onClose }) => (
                  <RoleTag name={label} closable={closable} onClose={onClose} />
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'groups'} label={'Groups'}>
              <Select
                loading={groupsList.loading}
                disabled // TODO change this when roles and groups are implemented
                // disabled={groupsList.loading}
                options={groupsList.data.map((group) => ({
                  label: group.name,
                  value: group._id,
                }))}
                mode={'multiple'}
                tagRender={({ label, value, closable, onClose }) => (
                  <GroupTag
                    name={label}
                    closable={closable}
                    onClose={onClose}
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ImportUsersForm
