import axiosBackendInstance from './backendInstance'

export const list = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get('/fb/group', {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
    params: {
      customer: data.customer,
      name: data.name,
      limit: requestProps.limit,
      page: requestProps.page,
      orderField: requestProps.orderField,
      orderDirection: requestProps.orderDirection,
    },
  })

  return responseData
}

export const details = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    `/fb/group/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const create = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.post(
    '/fb/group',
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const update = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.put(
    `/fb/group/${data.id}`,
    data.data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const deleteGroup = async (id, requestProps) => {
  await axiosBackendInstance.delete(`/fb/group/${id}`, {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
  })

  return id
}

export const bulkDeleteGroups = async (ids, requestProps) => {
  await axiosBackendInstance.post('/fb/group/bulkDelete', ids, {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
  })

  return ids
}
