import React from 'react'
import {
  Select,
  Button,
  PageHeader,
  Space,
  Row,
  Col,
  Card,
  List,
  Statistic,
} from 'antd'
import { RobotOutlined, MoreOutlined, UserOutlined } from '@ant-design/icons'

import classes from './WorkOrderManagementDashboard.less'
import ChartContainer from '../../components/Dashboard/ChartContainer'
import OnTimeVsOverdueChart from '../../components/WorkOrder/OnTimeVsOverdueChart'

const WorkOrderManagementDashboard = () => {
  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <PageHeader
            title={'Work Orders'}
            subTitle={'Dashboard'}
            ghost={false}
            extra={
              <Space>
                <Select
                  defaultValue={'everyone'}
                  size={'large'}
                  key={'selectEntity'}
                >
                  <Select.Option value={'everyone'}>
                    <RobotOutlined /> Everyone
                  </Select.Option>
                  <Select.Option value={'328742983462393d2'}>
                    <UserOutlined /> John Doe
                  </Select.Option>
                </Select>
                <Button size={'large'} icon={<MoreOutlined />} />
              </Space>
            }
          />
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col xs={24} md={16}>
          <ChartContainer
            charts={[
              {
                name: 'Completed vs Overdue',
                showChart: (labels, monthsSpan) => (
                  <OnTimeVsOverdueChart
                    labels={labels}
                    monthsSpan={monthsSpan}
                  />
                ),
              },
            ]}
          />
        </Col>
        <Col xs={24} md={8}>
          <Card
            className={classes.CardContainer}
            bodyStyle={{ overflow: 'auto', maxHeight: '400px' }}
            title={'Max amounts'}
            extra={
              <Select defaultValue={'wos'}>
                <Select.Option value={'wos'}>Most Work Orders</Select.Option>
                <Select.Option value={'3'}>Best Completed Ratio</Select.Option>
              </Select>
            }
          >
            <List
              dataSource={[
                { name: 'John Doe' },
                { name: 'Jane Doe' },
                { name: 'John Doe' },
                { name: 'Jane Doe' },
                { name: 'John Doe' },
                { name: 'Jane Doe' },
                { name: 'John Doe' },
                { name: 'Jane Doe' },
                { name: 'John Doe' },
                { name: 'Jane Doe' },
                { name: 'John Doe' },
                { name: 'Jane Doe' },
              ]}
              renderItem={(item, index) => (
                <List.Item extra={`${34 - index * 2}`}>
                  <List.Item.Meta avatar={<UserOutlined />} title={item.name} />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card
            className={classes.CardContainer}
            title={'Stats'}
            extra={
              <Select defaultValue={'1'}>
                <Select.Option value={'1'}>This month</Select.Option>
              </Select>
            }
          >
            <Row align={'middle'} gutter={[8, 16]}>
              <Col xs={24} md={12} lg={6}>
                <Statistic
                  title={'Average time spent to complete'}
                  value={'3h 15min'}
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Statistic
                  title={'Completed/In Progress Ratio'}
                  value={'66%'}
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Statistic title={'Overdue work orders'} value={4} />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Statistic title={'Work Orders in progress'} value={'67'} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default WorkOrderManagementDashboard
