import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Space, Form } from 'antd'

import { createFolder } from '../../../store/actions/resources.actions'

import ModifyDrawer from '../../ModifyDrawer'
import FolderForm from '../FolderForm'

const CreateFolder = ({
  visible,
  onDrawerClose,
  groupsList,
  currentPrefix,
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [creatingFolder] = useSelector((state) => [
    state.resources.creatingFolder,
  ])

  const createFolderHandler = (values) => {
    values.folderKey = `${currentPrefix}${values.folderName}/`

    dispatch(createFolder(values)).then(() => {
      form.resetFields()
      onDrawerClose()
    })
  }

  return (
    <ModifyDrawer
      title={'Create Folder'}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose}>Cancel</Button>
          <Button
            type={'primary'}
            onClick={() => form.submit()}
            loading={creatingFolder}
            disabled={creatingFolder}
          >
            Create
          </Button>
        </Space>
      }
      onDrawerClose={onDrawerClose}
      visible={visible}
    >
      <FolderForm
        form={form}
        onFinish={createFolderHandler}
        groupsList={groupsList}
      />
    </ModifyDrawer>
  )
}

export default CreateFolder
