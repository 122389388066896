import { createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'

export const showMessage = createAsyncThunk(
  'messages/showMessage',
  async ({ type = 'error', config = {} }) => {
    switch (type) {
      case 'info':
        message.info(config)
        break
      case 'success':
        message.success(config)
        break
      case 'loading':
        message.loading(config)
        break
      case 'error':
      default:
        message.error(config)
    }
  }
)
