import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Modal } from 'antd'

import { createWorkOrder } from '../../../store/actions/workOrders.actions'
import { fetchCustomersList } from '../../../store/actions/customers.actions'
import { fetchProjectsList } from '../../../store/actions/projects.actions'
import { fetchGroupsList } from '../../../store/actions/groups.actions'
import { fetchUsersList } from '../../../store/actions/users.actions'

import useAuth from '../../../hooks/useAuth'
import CombinedForm from '../CombinedForm'

const CreateWorkOrder = ({
  visible,
  onModalClose,
  forceIntoProject = null,
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { isSuperUser } = useAuth()
  const [
    currentUserData,
    creatingWorkOrder,
    customersList,
    projectsList,
    groupsList,
    usersList,
  ] = useSelector((state) => [
    state.users.me.data,
    state.workOrders.creatingWorkOrder,
    state.customers.list,
    state.projects.list,
    state.groups.list,
    state.users.list,
  ])
  const [currentCustomer, setCurrentCustomer] = useState(
    currentUserData.customer._id
  )
  const [currentProject, setCurrentProject] = useState(null)
  const [currentStage, setCurrentStage] = useState(0)
  const [addAnother, setAddAnother] = useState(false)

  useEffect(() => {
    if (visible && isSuperUser) {
      dispatch(fetchCustomersList())
    }
  }, [visible, isSuperUser, dispatch])

  useEffect(() => {
    if (visible) {
      dispatch(fetchProjectsList({ customer: currentCustomer }))
      dispatch(fetchGroupsList({ customer: currentCustomer }))
      dispatch(fetchUsersList({ customer: currentCustomer }))
    }
  }, [visible, dispatch, currentCustomer])

  const formFieldsChangeHandler = (changedFields) => {
    const customerFields = changedFields.filter(
      (changedField) => changedField.name[0] === 'customer'
    )
    const projectFields = changedFields.filter(
      (changedField) => changedField.name[0] === 'project'
    )

    if (customerFields.length > 0) {
      setCurrentCustomer(customerFields[0].value)
      form.resetFields([
        'project',
        'assignedToUsers',
        'assignedToGroups',
        'assetsTargeted',
      ])
    }
    if (projectFields.length > 0) {
      // Get the currently selected project (the whole object)
      setCurrentProject(
        _.find(projectsList.data, ['_id', projectFields[0].value])
      )
      form.resetFields(['assetsTargeted'])
    }
  }

  const createWorkOrderHandler = (values) => {
    dispatch(
      createWorkOrder({ customer: currentUserData.customer._id, ...values })
    ).then(() => {
      setCurrentStage(0)
      form.resetFields()
      if (!addAnother) {
        onModalClose()
      }
    })
  }

  return (
    <Modal
      title={'Create Work Order'}
      width={'90%'}
      visible={visible}
      onCancel={() => {
        setCurrentStage(0)
        setAddAnother(false)
        form.resetFields()
        onModalClose()
      }}
      footer={false}
    >
      <CombinedForm
        form={form}
        onFinish={createWorkOrderHandler}
        customersList={customersList}
        usersList={usersList}
        groupsList={groupsList}
        projectsList={projectsList}
        projectAssetTypes={currentProject?.assetTypes}
        currentCustomer={currentCustomer}
        currentStage={currentStage}
        setCurrentStage={setCurrentStage}
        addAnother={addAnother}
        setAddAnother={setAddAnother}
        onFieldsChange={formFieldsChangeHandler}
        showSuperUserFields={isSuperUser}
        forceIntoProject={forceIntoProject}
        creatingWorkOrder={creatingWorkOrder}
      />
    </Modal>
  )
}

export default CreateWorkOrder
