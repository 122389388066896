import { createReducer } from '@reduxjs/toolkit'

import {
  fetchCloudProvidersList,
  fetchCloudProvidersDetails,
  fetchCloudProvidersMe,
  createCloudProvider,
  updateCloudProviderSettings,
  performHealthCheck,
  syncWithCloudProvider,
  deleteCloudProvider,
} from '../actions/cloudProviders.actions'
import _ from 'lodash'

const initialState = {
  list: {
    loading: false,
    data: [],
  },
  details: {
    loading: false,
    data: null,
  },
  me: {
    loading: false,
    data: null,
  },
  health: {
    loading: false,
    status: null,
  },
  creatingCloudProvider: false,
  updatingCloudProviderSettings: false,
  syncingWithCloudProvider: false,
  deletingCloudProvider: false,
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCloudProvidersDetails.pending, (state, action) => {
      state.details.loading = true
      state.details.data = null
    })
    .addCase(fetchCloudProvidersDetails.fulfilled, (state, action) => {
      state.details.loading = false
      state.details.data = action.payload
    })
    .addCase(fetchCloudProvidersMe.pending, (state, action) => {
      state.me.loading = true
      state.me.data = null

      return state
    })
    .addCase(fetchCloudProvidersMe.fulfilled, (state, action) => {
      state.me.loading = false
      state.me.data = action.payload

      return state
    })
    .addCase(createCloudProvider.pending, (state, action) => {
      state.creatingCloudProvider = true

      return state
    })
    .addCase(createCloudProvider.fulfilled, (state, action) => {
      if (action.payload.superuser) {
        state.details.data = action.payload.response
      } else {
        state.me.data = action.payload.response
      }

      state.creatingCloudProvider = false
    })
    .addCase(createCloudProvider.rejected, (state, action) => {
      state.creatingCloudProvider = false

      return state
    })
    .addCase(updateCloudProviderSettings.pending, (state, action) => {
      state.updatingCloudProviderSettings = true

      return state
    })
    .addCase(updateCloudProviderSettings.fulfilled, (state, action) => {
      // Check if the list needs to be updated
      if (state.list.data) {
        const updatedIndex = _.findIndex(state.list.data, [
          '_id',
          action.payload._id,
        ])

        // Update the list
        state.list.data[updatedIndex] = action.payload
      }

      // Check if the details need to be updated
      if (state.details.data) {
        state.details.data = action.payload
      }

      // Check if the 'me' needs to be updated
      if (state.me.data?._id === action.payload._id) {
        state.me.data = action.payload
      }

      state.updatingCloudProviderSettings = false

      return state
    })
    .addCase(updateCloudProviderSettings.rejected, (state, action) => {
      state.updatingCloudProviderSettings = false

      return state
    })
    .addCase(performHealthCheck.pending, (state, action) => {
      state.health.loading = true
      state.health.status = null

      return state
    })
    .addCase(performHealthCheck.fulfilled, (state, action) => {
      state.health.loading = false
      state.health.status = !!action.payload

      return state
    })
    .addCase(performHealthCheck.rejected, (state, action) => {
      state.health.loading = false
      state.health.status = false

      return state
    })
    .addCase(syncWithCloudProvider.pending, (state, action) => {
      state.syncingWithCloudProvider = true

      return state
    })
    .addCase(syncWithCloudProvider.fulfilled, (state, action) => {
      state.syncingWithCloudProvider = false

      return state
    })
    .addCase(syncWithCloudProvider.rejected, (state, action) => {
      state.syncingWithCloudProvider = false

      return state
    })
    .addCase(deleteCloudProvider.pending, (state, action) => {
      // add to state
      // remove from states
      state.deletingCloudProvider = true

      return state
    })
    .addCase(deleteCloudProvider.fulfilled, (state, action) => {
      if (action.payload.superuser) {
        state.details.data = action.payload.response
      } else {
        state.me.data = action.payload.response
      }

      state.deletingCloudProvider = false

      return state
    })
    .addCase(deleteCloudProvider.rejected, (state, action) => {
      state.deletingCloudProvider = false

      return state
    })
})

export default reducer
