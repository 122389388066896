import { createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'

import * as workOrdersApi from '../../axios/workOrders'

export const fetchWorkOrdersList = createAsyncThunk(
  'workOrders/list',
  async (
    { name, project, customer, assignedTo, limit, page, order } = {},
    thunkAPI
  ) => {
    const response = await workOrdersApi.list(
      { name, project, customer, assignedTo },
      { token: thunkAPI.getState().auth.token, limit, page, order }
    )

    return response
  }
)

export const fetchWorkOrdersDetails = createAsyncThunk(
  'workOrders/details',
  async (id, thunkAPI) => {
    const response = await workOrdersApi.details(
      { id },
      { token: thunkAPI.getState().auth.token }
    )

    return response
  }
)

export const createWorkOrder = createAsyncThunk(
  'workOrders/create',
  async (data, thunkAPI) => {
    const response = await workOrdersApi.create(data, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Work Order created!')

    return response
  }
)

export const updateWorkOrder = createAsyncThunk(
  'workOrders/update',
  async ({ id, data }, thunkAPI) => {
    const response = await workOrdersApi.update(
      { id, data },
      { token: thunkAPI.getState().auth.token }
    )

    message.success('Work Order updated!')

    return response
  }
)

export const deleteWorkOrder = createAsyncThunk(
  'workOrders/delete',
  async (id, thunkAPI) => {
    await workOrdersApi.deleteWorkOrder(id, {
      token: thunkAPI.getState().auth.token,
    })

    message.success('Work Order deleted!')

    return id
  }
)

export const addCommentToWorkOrder = createAsyncThunk(
  'workOrders/addComment',
  async ({ id, data }, thunkAPI) => {
    const response = await workOrdersApi.addCommentToWorkOrder(
      { id, data },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    message.success('Comment added!')

    return response
  }
)

export const markCompleted = createAsyncThunk(
  'workOrders/markCompleted',
  async ({ id, data }, thunkAPI) => {
    const response = await workOrdersApi.markCompleted(
      { id, data },
      {
        token: thunkAPI.getState().auth.token,
      }
    )

    message.success('Work Order completed!')

    return response
  }
)
