import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Space, Button, Spin } from 'antd'

import {
  updateUser,
  fetchUsersDetails,
} from '../../../store/actions/users.actions'
import { fetchRolesList } from '../../../store/actions/roles.actions'
import { fetchGroupsList } from '../../../store/actions/groups.actions'

import ModifyDrawer from '../../ModifyDrawer'
import UserForm from '../UserForm'

const EditUser = ({ visible, onDrawerClose }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [
    usersMe,
    usersDetails,
    updatingUser,
    rolesList,
    groupsList,
  ] = useSelector((state) => [
    state.users.me,
    state.users.details,
    state.users.updatingUser,
    state.roles.list,
    state.groups.list,
  ])
  const [isFormTouched, setIsFormTouched] = useState(false)

  useEffect(() => {
    if (visible) {
      dispatch(fetchUsersDetails(visible))
    }
  }, [dispatch, visible])

  const usersCustomerId = usersDetails.data?.customer?._id
  useEffect(() => {
    if (visible && usersCustomerId) {
      dispatch(fetchRolesList({ customer: usersCustomerId }))
      dispatch(fetchGroupsList({ customer: usersCustomerId }))
    }
  }, [dispatch, visible, usersCustomerId])

  const { data: usersDetailsData } = usersDetails
  useEffect(() => {
    if (usersDetailsData) {
      form.setFieldsValue({
        name: usersDetailsData.name,
        email: usersDetailsData.email,
        roles: usersDetailsData?.roles.map((role) => role._id),
        groups: usersDetailsData?.groups.map((group) => group._id),
        superuser: Boolean(usersDetailsData.superuser),
      })
    }
  }, [usersDetailsData, form])

  const updateUserHandler = (values) => {
    dispatch(
      updateUser({ id: visible, data: { ...usersDetails.data, ...values } })
    ).then((thunkResult) => {
      if (thunkResult.meta.requestStatus !== 'rejected') {
        onDrawerClose()
      }
    })
  }

  return (
    <ModifyDrawer
      title={'Edit user'}
      visible={visible}
      onDrawerClose={onDrawerClose}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose} shape={'round'}>Cancel</Button>
          <Button
            type={'primary'}
            shape={'round'}
            onClick={() => form.submit()}
            loading={updatingUser}
            disabled={updatingUser || !isFormTouched}
          >
            Save
          </Button>
        </Space>
      }
    >
      {usersDetails.loading ? (
        <Spin />
      ) : (
        <UserForm
          form={form}
          onFinish={updateUserHandler}
          onValuesChange={() => setIsFormTouched(true)}
          rolesList={rolesList}
          groupsList={groupsList}
          showSuperUserFields={false}
          isUserMe={usersMe.data?._id === usersDetails.data?._id}
        />
      )}
    </ModifyDrawer>
  )
}

export default EditUser
