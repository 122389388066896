import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import qs from 'qs'

const useTable = () => {
  const history = useHistory()
  const { search } = useLocation()

  const queryStringDefaults = useMemo(() => {
    const queryObject = qs.parse(search, { ignoreQueryPrefix: true })

    if (!queryObject.limit || !queryObject.page) {
      queryObject.limit = 10
      queryObject.page = 1

      history.replace({
        search: qs.stringify(queryObject),
      })
    }

    return queryObject
  }, [history, search])

  const getQueryObject = useCallback(
    (pagination, filters, sorter) => {
      // Update the pagination query
      const query = {
        limit: pagination.pageSize,
        page: pagination.current,
      }

      // Update the sorting query
      if (sorter.column) {
        query.orderField = sorter.field
        query.orderDirection = sorter.order === 'ascend' ? 1 : -1
      }

      // Update the filter query
      const filteredFilters = _.reduce(
        filters,
        (result, field, fieldName) => {
          if (!_.isEmpty(field)) {
            if (field.length === 1) {
              result[fieldName] = field[0]
            } else {
              result[fieldName] = field
            }
          }

          return result
        },
        {}
      )

      const finalQuery = {
        ...query,
        ...filteredFilters,
      }

      // Update the url
      history.replace({
        search: qs.stringify(finalQuery),
      })

      return finalQuery
    },
    [history]
  )

  return {
    queryStringDefaults,
    getQueryObject,
  }
}

export default useTable
