import React from 'react'
import { Modal, Button, Typography } from 'antd'

const SignOutModal = ({
  visible = false,
  onModalClose = () => {},
  cloudProviderName = '',
  onSignOut,
}) => {
  const signOutHandler = () => {
    onModalClose()
    onSignOut()
  }

  return (
    <Modal
      visible={visible}
      onCancel={onModalClose}
      onOk={signOutHandler}
      title={'Are you sure?'}
    >
      <Typography.Text>
        Are you sure you want to sign out from {cloudProviderName}? Doing so
        will prevent FieldBox and FieldMap from accessing this account's
        resources.
      </Typography.Text>
    </Modal>
  )
}

export default SignOutModal
