import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  PageHeader,
  Dropdown,
  Menu,
  Card,
  Statistic,
  Row,
  Col,
  Grid,
} from 'antd'
import {
  EllipsisOutlined,
  DeleteOutlined,
  SettingOutlined,
  PlusOutlined,
} from '@ant-design/icons'

import { fetchProjectsDetails } from '../../store/actions/projects.actions'
import { deleteProject as deleteProjectAction } from '../../store/actions/projects.actions'
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal'
import EditProject from '../../components/Project/EditProject'
import ListWorkOrders from '../../components/WorkOrder/ListWorkOrders'
import CreateWorkOrder from '../../components/WorkOrder/CreateWorkOrder'

const ProjectDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const screens = Grid.useBreakpoint()
  const { id } = useParams()
  const [projectsDetails, deletingProject] = useSelector((state) => [
    state.projects.details,
    state.projects.deletingProject,
  ])
  const [createWorkOrder, setCreateWorkOrder] = useState(false)
  const [editProject, setEditProject] = useState(null)
  const [deleteProject, setDeleteProject] = useState(null)

  const isXs = screens.xs && !screens.sm
  const isSm = screens.sm && !screens.md

  useEffect(() => {
    dispatch(fetchProjectsDetails(id))
  }, [id, dispatch])

  const menu = (
    <Menu>
      {(isXs || isSm) && (
        <Menu.Item
          icon={<PlusOutlined />}
          onClick={() => setCreateWorkOrder(true)}
        >
          Add Work Order
        </Menu.Item>
      )}
      <Menu.Item icon={<SettingOutlined />} onClick={() => setEditProject(id)}>
        Edit
      </Menu.Item>
      <Menu.Item
        icon={<DeleteOutlined />}
        onClick={() => setDeleteProject(id)}
        danger
      >
        Delete
      </Menu.Item>
    </Menu>
  )

  const deleteProjectHandler = () => {
    dispatch(deleteProjectAction(id)).then(() => {
      setDeleteProject(null)
      history.push('/projects')
    })
  }

  return (
    <>
      <CreateWorkOrder
        visible={createWorkOrder}
        onModalClose={() => setCreateWorkOrder(false)}
        forceIntoProject={projectsDetails.data}
      />
      <ConfirmDeleteModal
        visible={deleteProject}
        onConfirmDelete={deleteProjectHandler}
        onModalClose={() => setDeleteProject(null)}
        deleting={deletingProject}
        magicPhrase={projectsDetails.data?.name}
      />
      <EditProject
        visible={editProject}
        onDrawerClose={() => setEditProject(null)}
      />
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <PageHeader
            title={projectsDetails.data?.name}
            subTitle={'Project details'}
            onBack={() => history.push('/projects')}
            extra={[
              isXs || isSm ? null : (
                <Button
                  key={'addWorkOrder'}
                  type={'primary'}
                  shape={'round'}
                  icon={<PlusOutlined />}
                  size={'large'}
                  onClick={() => setCreateWorkOrder(true)}
                >
                  Add Work Order
                </Button>
              ),
              <Dropdown key={'more'} overlay={menu}>
                <Button
                  // style={{
                  //   border: 'none',
                  //   padding: 0,
                  // }}
                  type={'ghost'}
                  shape={'round'}
                  size={'large'}
                >
                  <EllipsisOutlined
                    style={{
                      fontSize: 30,
                      verticalAlign: 'top',
                    }}
                  />
                </Button>
              </Dropdown>,
            ]}
            ghost={false}
            footer={false}
          />
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <Card title={'Info'}>
            <Row align={'middle'}>
              <Col xs={24} sm={12} lg={6}>
                <Statistic title={'Connected map'} value={'Huston Large'} />
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Statistic
                  title={'Created at'}
                  value={moment(projectsDetails.data?.createdAt).format(
                    'MM/DD/Y'
                  )}
                />
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Statistic title={'Open work orders'} value={20} />
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Statistic title={'Completed work orders'} value={14} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <Card title={'Work Orders'}>
            <ListWorkOrders onlyProjectId={id} showProjectColumn={false} />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ProjectDetails
