import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'

import {
  fetchLicensesList,
  approveLicense,
  revokeLicense,
} from '../actions/licenses.actions'

const initialState = {
  list: {
    loading: false,
    data: [],
    total: 0,
  },
  approvingLicense: false,
  revokingLicense: false,
}

const licensesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchLicensesList.pending, (state, action) => {
      state.list.data = []
      state.list.loading = true
      state.list.total = 0
    })
    .addCase(fetchLicensesList.fulfilled, (state, action) => {
      state.list.data = action.payload.list || action.payload || []
      state.list.loading = false
      state.list.total = action.payload.total
    })
    .addCase(approveLicense.pending, (state, action) => {
      state.approvingLicense = true
    })
    .addCase(approveLicense.fulfilled, (state, action) => {
      const approvedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[approvedIndex] = action.payload

      state.approvingLicense = false
    })
    .addCase(approveLicense.rejected, (state, action) => {
      state.approvingLicense = false
    })
    .addCase(revokeLicense.pending, (state, action) => {
      state.revokingLicense = true
    })
    .addCase(revokeLicense.fulfilled, (state, action) => {
      const revokedIndex = _.findIndex(state.list.data, [
        '_id',
        action.payload._id,
      ])

      // Update the list
      state.list.data[revokedIndex] = action.payload

      state.revokingLicense = false
    })
    .addCase(revokeLicense.rejected, (state, action) => {
      state.revokingLicense = false
    })
})

export default licensesReducer
