import { createReducer } from '@reduxjs/toolkit'

import * as APP_TYPES from '../../constants/applicationTypes'
import { applicationChange } from '../actions/application.actions'

const initialState = {
  active:
    localStorage.getItem('active_application') ||
    APP_TYPES.ORGANIZATION_MANAGEMENT,
    //APP_TYPES.WORK_ORDER_MANAGEMENT,
}

const applicationReducer = createReducer(initialState, (builder) => {
  builder.addCase(applicationChange, (state, action) => {
    localStorage.setItem('active_application', action.payload)

    state.active = action.payload
  })
})

export default applicationReducer
