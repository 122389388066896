import React from 'react'
import moment from 'moment'
import { Badge, Tooltip, Space, Typography } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'

const TimeToDueDateIcon = ({ deadline }) => {
  const momentDeadline = moment(deadline)
  const daysUntilDeadline = Math.floor(momentDeadline.diff(moment(), 'days'))
  let tooltipTitle,
    badgeColor = '#3f51b5',
    dateDescription = momentDeadline.format('MM/DD/YY [at] HH:mm')

  if (daysUntilDeadline > 0) {
    tooltipTitle = `${daysUntilDeadline} day${
      daysUntilDeadline > 1 ? 's' : ''
    } left`
    badgeColor = '#16d37f'
  } else if (daysUntilDeadline === 0) {
    tooltipTitle = `Due today at ${momentDeadline.format('HH:mm')}`
    badgeColor = '#3f51b5'
    dateDescription = momentDeadline.format('[Today at] HH:mm')
  } else {
    tooltipTitle = `${Math.abs(daysUntilDeadline)} day${
      Math.abs(daysUntilDeadline) > 1 ? 's' : ''
    } overdue`
    badgeColor = '#f44336'
  }

  return (
    <Space size={'middle'}>
      <Tooltip title={tooltipTitle}>
        <Badge
          count={Math.abs(daysUntilDeadline)}
          size={'small'}
          style={{ backgroundColor: badgeColor }}
          showZero
        >
          <CalendarOutlined />
        </Badge>
      </Tooltip>
      <Typography.Text>{dateDescription}</Typography.Text>
    </Space>
  )
}

export default TimeToDueDateIcon
