import React, { useState, useEffect, Children } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, PageHeader, Dropdown, Menu, Row, Col } from 'antd'
import {
  EllipsisOutlined,
  DeleteOutlined,
  SettingOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'

import classes from './WorkOrderDetails.less'
import {
  fetchWorkOrdersDetails,
  addCommentToWorkOrder,
  deleteWorkOrder as deleteWorkOrderAction,
} from '../../store/actions/workOrders.actions'

import useAuth from '../../hooks/useAuth'
import EditWorkOrder from '../../components/WorkOrder/EditWorkOrder'
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal'
import MarkComplete from '../../components/WorkOrder/MarkComplete'
import CommentSection from '../../components/WorkOrder/CommentSection'
import WorkOrderTopInfo from '../../components/WorkOrder/WorkOrderTopInfo'
import WorkOrderCompletedSection from '../../components/WorkOrder/WorkOrderCompletedSection'
import WorkOrderInfoSection from '../../components/WorkOrder/WorkOrderInfoSection'

const WorkOrderDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isSuperUser, hasAction, allActions } = useAuth()
  const { id } = useParams()
  const [
    workOrdersDetails,
    deletingWorkOrder,
    addingCommentToWorkOrder,
  ] = useSelector((state) => [
    state.workOrders.details,
    state.workOrders.deletingWorkOrder,
    state.workOrders.addingCommentToWorkOrder,
  ])
  const [markComplete, setMarkComplete] = useState(null)
  const [editWorkOrder, setEditWorkOrder] = useState(null)
  const [deleteWorkOrder, setDeleteWorkOrder] = useState(null)

  useEffect(() => {
    dispatch(fetchWorkOrdersDetails(id))
  }, [dispatch, id])

  const menu = (
    <Menu>
      {!workOrdersDetails.data?.completed && (
        <Menu.Item
          icon={<CheckCircleOutlined />}
          onClick={() => setMarkComplete(id)}
          className={classes.GreenColor}
        >
          Mark as Complete
        </Menu.Item>
      )}
      {!workOrdersDetails.data?.completed &&
        hasAction(allActions.adminactions) && (
          <Menu.Item
            icon={<SettingOutlined />}
            onClick={() => setEditWorkOrder(id)}
          >
            Edit
          </Menu.Item>
        )}
      {hasAction(allActions.adminactions) && (
        <Menu.Item
          icon={<DeleteOutlined />}
          onClick={() => setDeleteWorkOrder(id)}
          danger
        >
          Delete
        </Menu.Item>
      )}
    </Menu>
  )

  const addCommentToWorkOrderHandler = (values) => {
    dispatch(addCommentToWorkOrder({ id, data: values }))
  }

  const deleteWorkOrderHandler = () => {
    dispatch(deleteWorkOrderAction(id)).then(() => {
      setDeleteWorkOrder(null)
      history.push('/workOrders')
    })
  }
  return (
    <>
      <EditWorkOrder
        visible={editWorkOrder}
        onModalClose={() => setEditWorkOrder(null)}
        existingWorkOrderDetails={workOrdersDetails}
      />
      <ConfirmDeleteModal
        visible={deleteWorkOrder}
        onConfirmDelete={deleteWorkOrderHandler}
        onModalClose={() => setDeleteWorkOrder(null)}
        deleting={deletingWorkOrder}
        magicPhrase={workOrdersDetails.data?.name}
      />
      <MarkComplete
        visible={markComplete}
        onDrawerClose={() => setMarkComplete(null)}
      />
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <PageHeader
            title={workOrdersDetails.data?.name}
            subTitle={'Work Order details'}
            onBack={() => history.push('/workOrders')}
            extra={[
              menu.props.children.filter((child) => child).length > 0 && (
                <Dropdown key="more" overlay={menu}>
                  <Button
                    style={{
                      border: 'none',
                      padding: 0,
                    }}
                  >
                    <EllipsisOutlined
                      style={{
                        fontSize: 20,
                        verticalAlign: 'top',
                      }}
                    />
                  </Button>
                </Dropdown>
              ),
            ]}
            ghost={false}
          />
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <WorkOrderTopInfo workOrdersDetails={workOrdersDetails} />
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <WorkOrderCompletedSection
            completionStatus={workOrdersDetails.data?.completed}
            workOrdersDetails={workOrdersDetails}
          />
        </Col>
      </Row>

      {!workOrdersDetails.data?.completed && (
        <Row gutter={[8, 16]}>
          <Col xs={24} md={14} lg={16}>
            <WorkOrderInfoSection workOrdersDetails={workOrdersDetails} />
          </Col>
          <Col xs={24} md={10} lg={8}>
            <CommentSection
              comments={workOrdersDetails.data?.comments}
              loading={workOrdersDetails.loading}
              postComment={addCommentToWorkOrderHandler}
              postingComment={addingCommentToWorkOrder}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default WorkOrderDetails
