import React from 'react'
import { Tag } from 'antd'
import { UserOutlined } from '@ant-design/icons'

const UserTag = ({ name, ...props }) => (
  <Tag color={'blue'} icon={<UserOutlined />} {...props}>
    {name}
  </Tag>
)

export default UserTag
