import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Form, Space } from 'antd'

import { changePassword } from '../../../store/actions/users.actions'
import ModifyDrawer from '../../ModifyDrawer'
import ChangePasswordForm from '../ChangePasswordForm'

const ChangePassword = ({ visible, onDrawerClose }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [userData, changingPassword] = useSelector((state) => [
    state.users.me.data,
    state.users.changingPassword,
  ])

  const changePasswordHandler = (values) => {
    dispatch(
      changePassword({
        id: userData._id,
        prevPassword: values.prevPassword,
        newPassword: values.newPassword,
      })
    ).then(() => {
      onDrawerClose()
    })
  }

  return (
    <ModifyDrawer
      title={'Change password'}
      footer={
        <Space size={'small'}>
          <Button onClick={onDrawerClose} shape={'round'}>Cancel</Button>
          <Button
            type={'primary'}
            shape={'round'}
            onClick={() => form.submit()}
            loading={changingPassword}
            disabled={changingPassword}
          >
            Change
          </Button>
        </Space>
      }
      visible={visible}
      onDrawerClose={onDrawerClose}
    >
      <ChangePasswordForm form={form} onFinish={changePasswordHandler} />
    </ModifyDrawer>
  )
}

export default ChangePassword
