import { configureStore } from '@reduxjs/toolkit'

import applicationReducer from './reducers/application.reducer'
import authReducer from './reducers/auth.reducer'
import usersReducer from './reducers/users.reducer'
import customersReducer from './reducers/customers.reducer'
import rolesReducer from './reducers/roles.reducer'
import groupsReducer from './reducers/groups.reducer'
import licensesReducer from './reducers/licenses.reducer'
import devicesReducer from './reducers/devices.reducer'
import cloudProvidersReducer from './reducers/cloudProviders.reducer'
import resourcesReducer from './reducers/resources.reducer'
import projectsReducer from './reducers/projects.reducer'
import assetTypesReducer from './reducers/assetTypes.reducer'
import workOrdersReducer from './reducers/workOrders.reducer'

const rootReducer = {
  application: applicationReducer,
  auth: authReducer,
  users: usersReducer,
  customers: customersReducer,
  roles: rolesReducer,
  groups: groupsReducer,
  licenses: licensesReducer,
  devices: devicesReducer,
  cloudProviders: cloudProvidersReducer,
  resources: resourcesReducer,
  projects: projectsReducer,
  assetTypes: assetTypesReducer,
  workOrders: workOrdersReducer,
}

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})

export default store
