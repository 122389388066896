import React from 'react'
import { Card, Row, Progress, Statistic } from 'antd'

import classes from './Widget.less'

const Widget = ({ title, value, percent }) => {
  return (
    <Card>
      <Row gutter={[0, 16]}>
        <Statistic title={title} value={value} />
      </Row>
      <Row gutter={16}>
        <Progress type={'line'} percent={percent} />
      </Row>
    </Card>
  )
}

export default Widget
