import axiosBackendInstance from './backendInstance'

export const list = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    '/wom/workOrder',
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
      params: {
        name: data.name,
        project: data.project,
        customer: data.customer,
        assignedTo: data.assignedTo,
        limit: requestProps.limit,
        page: requestProps.page,
        order: requestProps.order,
      },
    }
  )

  return responseData
}

export const details = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.get(
    `/wom/workOrder/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const create = async (data, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.post(
    '/wom/workOrder',
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const update = async ({ id, data }, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.put(
    `/wom/workOrder/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const deleteWorkOrder = async (id, requestProps) => {
  await axiosBackendInstance.delete(`/wom/workOrder/${id}`, {
    headers: {
      Authorization: `Bearer ${requestProps.token}`,
    },
  })

  return id
}

export const addCommentToWorkOrder = async ({ id, data }, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.post(
    `/wom/workOrder/${id}/addComment`,
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}

export const markCompleted = async ({ id, data }, requestProps) => {
  const { data: responseData } = await axiosBackendInstance.post(
    `/wom/workOrder/${id}/markCompleted`,
    data,
    {
      headers: {
        Authorization: `Bearer ${requestProps.token}`,
      },
    }
  )

  return responseData
}
